import { appRouteEnums, menuTitleEnums } from "@enums";
import { Box, Grid, Button } from "@mui/material";
import { selectAuth, updateSnackBar, useAppSelector } from "@redux";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { weeklyScheduleApi } from "src/Api/weekly-schedule";
import {
    AppCustomeSelect,
    AppDatePicker,
    AppExportCsv,
    AppIconButton,
    AppTopBar,
    CircularLoader,
} from "../Common";
import { TitleMenuComponent } from "../TitleMenuComponent";
import { StyledCalendar } from "./styles";
import { ViewGraphCalendar } from "./ViewGraphCalendar";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { timesheetApi } from "@api";
import { checkExistUserSelected, linkImage } from "@utils";
import tick_icon from "@assets/Icons/tick_icon.svg";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const defaultGroup = {
    id: 0,
    name: "全て",
};

export const WeeklyScheduleScreen = () => {
    const auth = useAppSelector(selectAuth);
    const id = auth?.userInfo?.id;

    const [listName, setListName] = React.useState<any>([]);
    const [listHolidayDate, setListHolidayDate] = React.useState<any>();
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState<boolean>(false);
    const history = useHistory();
    const [date, setDate] = React.useState(new Date());
    const [groups, setGroups] = React.useState<any>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
    const [sortNameId, setSortNameId] = React.useState<any>();
    const [worksInConstructionParams, setWorksInConstructionParams] = React.useState<any>({
        year: moment(new Date()).format("YYYY"),
        month: moment(new Date()).format("MM"),
        limit: 10,
        page: 1,
    });
    const [inputValue, setValueInput] = React.useState<any>();
    const [users, setUsers] = React.useState<any>([]);
    const [disabledViewMore, setDisabledViewMore] = React.useState<any>(false);
    // const [timesheetListData, setTimeSheeListData] = React.useState<any>([]);
    // const [inputValue, setValueInput] = React.useState<any>();

    const handleChangeMonth = (date: any) => {
        setDate(date);
        setWorksInConstructionParams({
            year: moment(date).format("YYYY"),
            month: moment(date).format("MM"),
            limit: 10,
            page: 1,
        });
        setListName([]);
    };

    const goToOverTime = () => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/new`);
    };
    const goToTimeSheet = () => {
        history.push(`${appRouteEnums.PATH_TIMESHEET}`);
    };
    useEffect(() => {
        // Reset listName khi sortNameId thay đổi
        setListName([]);
    }, [sortNameId, inputValue]);
    const fetchApiDetailWorkSchedule = async () => {
        setLoading(true);
        try {
            const responseGroups = await timesheetApi.listGroupsTimesheet();
            setGroups(responseGroups.data);
            const responseListWorks = await weeklyScheduleApi.getListWorksInConstruction(
                worksInConstructionParams,
                selectedGroup,
                sortNameId,
                inputValue?.value
            );
            setSelectedGroup(selectedGroup ? selectedGroup : 0);

            const sortedArray = responseListWorks.data.data.user_auth
                ? [...responseListWorks.data.data.user_auth, ...responseListWorks.data.data.users]
                : [...responseListWorks.data.data.users];
            const resDateHoliday = responseListWorks.data.data.date_holiday;
            setDisabledViewMore(
                worksInConstructionParams.limit * worksInConstructionParams.page >=
                    responseListWorks.data.data.total
                    ? true
                    : false
            );

            const responseListUsers = await timesheetApi.listUsersInGroup(selectedGroup);
            setUsers(responseListUsers.data.data);

            setListName((prev: any) => [...prev, ...sortedArray]);

            setListHolidayDate(resDateHoliday);
            setLoading(false);
            // setListName(responseGroups.data.data.users);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
        }
    };

    const handleSelectGroup = (id) => {
        // setValueInput("");
        setListName([]);
        setWorksInConstructionParams((prev: any) => ({
            ...prev,
            page: 1,
        }));
        if (id === 0) {
            setSelectedGroup([]);
            return;
        }
        setSelectedGroup(
            checkExistUserSelected(id, selectedGroup)
                ? selectedGroup.filter((e) => e !== id)
                : [...selectedGroup, id]
        );
    };

    const handleViewMore = useCallback(() => {
        setWorksInConstructionParams((prev: any) => ({
            ...prev,
            page: prev.page + 1,
        }));
    }, []);

    const handleOnChange = (value) => {
        setValueInput(value);
    };

    useEffect(() => {
        fetchApiDetailWorkSchedule();
    }, [worksInConstructionParams, selectedGroup, sortNameId, inputValue]);

    return (
        <StyledCalendar style={{ maxHeight: "500px", position: "relative" }}>
            <AppTopBar />
            <CircularLoader loading={loading} />
            <div className="workSchedule">
                <div className="timesheet__title">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_WEEKLY_SCHEDULE} />
                </div>
                <span className="wrapper-nav">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                            className="newOT__top-icon"
                            onClick={() => history.push(appRouteEnums.PATH_HOME)}
                        >
                            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                        </p>
                        <div className="newOT__btn" onClick={goToTimeSheet}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {menuTitleEnums.TITLE_TIMESHEET}
                            </Box>
                        </div>
                        <div className="newOT__btn" onClick={goToOverTime}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {menuTitleEnums.TITLE_OVERTIME}
                            </Box>
                        </div>
                    </div>
                    <Grid item xs={12} md={6}>
                        <div className="wrapperInput">
                            <div className="datePicker">
                                <div
                                    className="label"
                                    // style={{
                                    //     marginTop: "10px",
                                    //     marginRight: "10px",
                                    // }}
                                >
                                    日付
                                </div>
                                <div className="wrapperDatePicker">
                                    <AppDatePicker
                                        date={date}
                                        handleChange={handleChangeMonth}
                                        format="yyyy/MM"
                                        views={["year", "month"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </span>
                <div className="wrapper__filter">
                    <div className="timesheet__filter">
                        <div
                            onClick={() => handleSelectGroup(defaultGroup.id)}
                            className={clsx(
                                !selectedGroup.length ? "active" : "",
                                "timesheet__filter-item"
                            )}
                        >
                            <span className="title">{defaultGroup.name}</span>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {!selectedGroup.length && <img src={tick_icon} alt="icon" />}
                            </Box>
                        </div>
                        {groups &&
                            groups.map((item) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleSelectGroup(item.id)}
                                    className={clsx(
                                        checkExistUserSelected(item.id, selectedGroup)
                                            ? "active"
                                            : "",
                                        "timesheet__filter-item"
                                    )}
                                >
                                    <span className="title">{item.name}</span>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {checkExistUserSelected(item.id, selectedGroup) && (
                                            <img src={tick_icon} alt="icon" />
                                        )}
                                    </Box>
                                </div>
                            ))}
                    </div>
                </div>
                <StyledWrapSearch className="wrapper__search">
                    <div className="wrapperSelect">
                        <AppCustomeSelect
                            placeholder="人を選択する"
                            onChange={handleOnChange}
                            groupUserOptions={
                                users?.length &&
                                [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                    return {
                                        label: `${user.last_name}${user.first_name}`,
                                        value: user?.id?.toString(),
                                        avatar: linkImage(user?.avatar?.path),
                                    };
                                })
                            }
                            options={
                                users?.length &&
                                [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                    return {
                                        label: `${user.last_name}${user.first_name}`,
                                        value: user?.id?.toString(),
                                        avatar: linkImage(user?.avatar?.path),
                                    };
                                })
                            }
                            value={inputValue}
                            isShowAvatar
                            isClearable
                        />
                    </div>
                </StyledWrapSearch>
                <div style={{ marginBottom: "20px" }}>
                    <AppExportCsv type={"weekly-schedule"} />
                </div>
                <div className="desktop">
                    <ViewGraphCalendar
                        data={listName}
                        date={date}
                        listHolidayDate={listHolidayDate}
                        worksInConstructionParams={worksInConstructionParams}
                        setSortNameId={setSortNameId}
                    />
                </div>
                <Button
                    className={`view-more-btn ${disabledViewMore && "disabled"}`}
                    variant="outlined"
                    disabled={disabledViewMore}
                    onClick={handleViewMore}
                >
                    もっと見る
                </Button>
            </div>
        </StyledCalendar>
    );
};

export default WeeklyScheduleScreen;

const StyledWrapSearch = styled.div`
    max-width: 300px;
    margin-bottom: 20px;
`;
