import React from "react";
import { Grid } from "@mui/material";

import { StyledListItem, ItemComponent } from ".";

interface IProps{
    historyLogs?:any;
}
export const ListItemsComponent = (props:IProps) => {
    const {historyLogs} = props;
    return (
        <StyledListItem>
            <Grid container columnSpacing={8}>
                {historyLogs && historyLogs.map((item,index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <ItemComponent data={item} />
                    </Grid>
                ))}
            </Grid>
        </StyledListItem>
    );
};
