import * as React from "react";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { StyledOverTimeView, StyledWrapCalendarComponent, OverTimeToggleModal } from ".";
import {
    AppTopBar,
    TitleMenuComponent,
    CircularLoader,
    AppIconButton,
    AppCustomeSelect,
    AppDatePicker,
    AppExportCsv,
} from "..";
import { PlusIcon } from "@assets";
import clsx from "clsx";
import { overtimeApi, timesheetApi } from "@api";
import { useAppSelector, selectAuth } from "@redux";
import { OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { checkExistUserSelected, filterOverTimeTitle, linkImage } from "@utils";
import tick_icon from "@assets/Icons/tick_icon.svg";
import { OverTimeCalendar } from "./OverTimeCalendar";
import styled from "styled-components";

const defaultGroup = {
    id: 0,
    name: "全て",
};

export const OverTimeView = () => {
    // redux States
    const auth = useAppSelector(selectAuth);
    const id = auth?.userInfo?.id;

    // hooks
    const history = useHistory();

    const currentDate = new Date();
    const initialYear = currentDate.getFullYear();
    const initialMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
    const initialCalendarMonth = (`${initialYear}-${initialMonth}`);
    const initialCsvStartDate = new Date(Date.UTC(initialYear, currentDate.getMonth(), 1));
    const initialCsvEndDate = new Date(Date.UTC(initialYear, currentDate.getMonth() + 1, 0));

    // component states
    // const [radioValue, setRadioValue] = React.useState<any>("0");
    const [inputValue, setInputValue] = React.useState<any>("");
    // const [listOT, setListDate] = React.useState<Array<any>>();
    const [users, setUsers] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
    // const [optionGroups, setOptionGroup] = React.useState<any>([]);
    // const [optionReasonOT, setOptionReasonOT] = React.useState<any>([]);

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [timeSheetDateData, setTimeSheetDateData] = React.useState<any>();
    const [overTimeIdData, setOverTimeIdData] = React.useState<any>();
    const [overTimeGroupIdData, setOverTimeGroupIdData] = React.useState<any>();
    // const [timesheetListData, setTimeSheeListData] = React.useState<any>([]);
    const [date, setDate] = React.useState(new Date());
    const [dataListOT, setDataListOT] = React.useState<any>([]);
    // const [inputValue, setValueInput] = React.useState<any>();
    const [calendarMonth, setCalendarMonth] = React.useState<string>(initialCalendarMonth);
    const [cvsStartDate, setCvsStartDate] = React.useState<string>(initialCsvStartDate.toISOString().split('T')[0]);
    const [cvsEndDate, setCvsEndDate] = React.useState<string>(initialCsvEndDate.toISOString().split('T')[0]);

    // fetch api
    const fetchApiListOT = async () => {
        setLoading(true);
        try {
            // const responseGroups = await timesheetApi.listGroupsTimesheet();
            const responseListUsers = await timesheetApi.listUsersInGroup(1);
            // setOptionGroup(responseGroups.data);
            // const responseListReasons = await overtimeApi.getReasonsOT();
            // setOptionReasonOT(responseListReasons.data.data);
            setUsers(responseListUsers.data.data);
            // const res = await overtimeApi.getListOT({ userId: inputValue?.value }, selectedGroup);
            // const res = await overtimeApi.getListOTCalendar(
            //     { userId: inputValue?.value },
            //     {
            //         month: `${moment(date).format("YYYY-MM")}`,
            //         overtimeReasonId: [...selectedGroup],
            //     }
            // );
            const responseFilteredByType = await overtimeApi.getListByTypeFilter({
                //month: `${moment(date).format("YYYY-MM")}`,
                month: calendarMonth,
                typeTitles: [...selectedGroup],
                userId: inputValue?.value,
            });
            // setDataListOT(res.data);
            setDataListOT(responseFilteredByType.data);
            // setListDate(res.data.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };
    // const fetchListOTCalendar = async () => {
    //     try {
    //         const res = await overtimeApi.getListOTCalendar({
    //             month: `${moment(date).format("YYYY-MM")}`,
    //         });
    //     } catch (error) {}
    // };
    // React.useEffect(() => {
    //     fetchListOTCalendar();
    // }, [date]);

    React.useEffect(() => {
        fetchApiListOT();
        return () => {
            // do something clean up
        };
    }, [selectedGroup, inputValue, calendarMonth /*, date*/]);
    // events
    const handleOnChange = (value: string) => {
        setInputValue(value);
    };
    // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRadioValue((event.target as HTMLInputElement).value);
    //     setInputValue("");
    // };
    const handleViewDetail = (values: { otId; userId; status }) => {
        if ((id === values.userId && values.status === OT_STATUS_PENDING) || (id === values.userId && values.status === OT_STATUS_REJECT)) {
            history.push(`${appRouteEnums.PATH_OVERTIME}/edit/${values.otId}`);
        } else {
            history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otId}`);
        }
    };
    const handleCreateNewOT = () => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/new`);
    };
    const handleSelectGroup = (id) => {
        setInputValue("");
        if (id === 0) {
            setSelectedGroup([]);
            return;
        }
        setSelectedGroup(checkExistUserSelected(id, selectedGroup) ? selectedGroup.filter((e) => e !== id) : [...selectedGroup, id]);
    };

    const handleOpenModal = (date: any, id: number) => {
        setOpenModal(true);
        setTimeSheetDateData({
            date: date.startDate[0],
        });
        setOverTimeIdData({
            id,
        });
        // setOverTimeGroupIdData({ groupId });
    };
    const handleOpenModalId = (date: any, id: number, groupId: number) => {
        setOpenModal(true);
        setTimeSheetDateData({
            date: date.startDate,
        });
        setOverTimeIdData({
            id,
        });
        setOverTimeGroupIdData({ groupId });
    };

    const handleCloseToggle = () => {
        setOpenModal(false);
        setTimeSheetDateData([]);
        setOverTimeIdData("");
        setOverTimeGroupIdData("");
    };
    const handleChange = (date: any) => {
        setDate(date);
    };
    const handleCalendarMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedMonth = e.target.value;
        setCalendarMonth(selectedMonth);

        const [year, month] = selectedMonth.split('-').map(Number);

        const firstDay = new Date(Date.UTC(year, month - 1, 1));
        setCvsStartDate(firstDay.toISOString().split('T')[0]);

        const lastDay = new Date(Date.UTC(year, month, 0));
        setCvsEndDate(lastDay.toISOString().split('T')[0]);
    };
    return (
        <StyledOverTimeView>
            <AppTopBar />
            <OverTimeToggleModal openToogle={openModal} anchor="right" handleCloseToggle={handleCloseToggle} timeSheetDateData={timeSheetDateData} id={overTimeIdData} groupId={overTimeGroupIdData} />
            <div className="overtime">
                <p className="newOT__top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
                <div className="overtime__title">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_OVERTIME} />
                    <WrapLink onClick={() => history.push(appRouteEnums.PATH_WEEKLY_SCHEDULE)}>週間予定</WrapLink>
                    <div className="overtime__title-btn">
                        <Button style={{ padding: "10px", borderRadius: "5px" }} onClick={() => handleCreateNewOT()} variant="outlined" startIcon={<PlusIcon />}>
                            新規作成
                        </Button>
                    </div>
                </div>
                <div className="overtime__listItem">
                    {/* <div className="form">
                        <div className="form__searchInput">
                            <AppInput
                                value={inputValue}
                                iconStart={<SearchIcon />}
                                handleChange={handleOnChange}
                                placeholder={PLACEHOLDER_SEARCH}
                                disabled={false}
                                name="searchValue"
                                onClick={handleOnSearchSubmit}
                            />
                        </div>
                        <div className="form__checkbox">
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={radioValue}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value={0}
                                    className={clsx(radioValue === 0 ? "active" : "")}
                                    control={<Radio />}
                                    label="全員"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="私の申請"
                                    className={clsx(radioValue === 1 ? "active" : "")}
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                    label="ブループ名"
                                    className={clsx(radioValue === 2 ? "active" : "")}
                                />
                            </RadioGroup>
                        </div>
                    </div> */}
                    <div className="overtime__form">
                        <div className="wrapper__filter">
                            <div className="overtime__filter">
                                <div onClick={() => handleSelectGroup(defaultGroup.id)} className={clsx(!selectedGroup.length ? "active" : "", "overtime__filter-item")}>
                                    <span className="title">{defaultGroup.name}</span>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>{!selectedGroup.length && <img src={tick_icon} alt="icon" />}</Box>
                                </div>
                                {filterOverTimeTitle &&
                                    filterOverTimeTitle.map((item) => (
                                        <div key={item.id} onClick={() => handleSelectGroup(item.name)} className={clsx(checkExistUserSelected(item.name, selectedGroup) ? "active" : "", "overtime__filter-item")}>
                                            <span className="title">{item.name}</span>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>{checkExistUserSelected(item.name, selectedGroup) && <img src={tick_icon} alt="icon" />}</Box>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="overtime__search">
                            <div className="wrapperSelect">
                                <AppCustomeSelect
                                    placeholder="人を選択する"
                                    onChange={handleOnChange}
                                    groupUserOptions={
                                        users?.length &&
                                        [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                            return {
                                                label: `${user.last_name}${user.first_name}`,
                                                value: user?.id?.toString(),
                                                avatar: linkImage(user?.avatar?.path),
                                            };
                                        })
                                    }
                                    options={
                                        users?.length &&
                                        [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                            return {
                                                label: `${user.last_name}${user.first_name}`,
                                                value: user?.id?.toString(),
                                                avatar: linkImage(user?.avatar?.path),
                                            };
                                        })
                                    }
                                    value={inputValue}
                                    isShowAvatar
                                />
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="wrapperLoading">
                            <CircularLoader loading={loading} type="fullContent" />
                        </div>
                    ) : (
                        // dataListOT && (
                        // listOT.map((item, index) => (
                        //     <div key={index}>
                        //         <OTItem data={item} handleViewDetail={handleViewDetail} />
                        //     </div>
                        // ))
                        <StyledWrapCalendarComponent>
                            <div className="cvsExportDiv">
                                <span className="csvExportTitle">CSVエクスポート</span>
                                <div className="ot__datePicker">

                                  {/* BA-006 start */}
                                    <div className="dateContainer">

                                    <div className="label">作成日時</div>

                                    <div className="dateSubContainer">
                                      <div className="wrapperDatePicker" style={{display: "none"}}>
                                          <AppDatePicker date={date} handleChange={handleChange} format="yyyy/MM" views={["year", "month"]} />
                                      </div>
                                      <input type="date" className="inputDate" value={cvsStartDate} onChange={(e) => setCvsStartDate(e.target.value)} /><span>から</span>
                                    </div>

                                    <div className="dateSubContainer">
                                      <input type="date" className="inputDate" value={cvsEndDate} onChange={(e) => setCvsEndDate(e.target.value)} /><span>まで</span>
                                    </div>

                                    </div>
                                </div>
                                <div>
                                    <AppExportCsv type={"overtimes"} startDate={cvsStartDate} endDate={cvsEndDate} />
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                <input type="month" className="inputMonth" value={calendarMonth} onChange={handleCalendarMonthChange} />
                            </div>
                            <div className="ot__calendar">
                                <OverTimeCalendar handleOpenModal={handleOpenModal} handleOpenModalId={handleOpenModalId} handleCloseToggle={handleCloseToggle} dataListOT={dataListOT} month={calendarMonth} handleViewDetail={handleViewDetail} />
                            </div>
                        </StyledWrapCalendarComponent>
                        // )
                    )}
                </div>
            </div>
        </StyledOverTimeView>
    );
};

const WrapLink = styled.div`
    font-weight: 700;
    margin-top: 27px;

    border: 2px solid orange;
    color: orange;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 8px 10px;
    :hover {
        cursor: pointer;
        color: #d88d02;
        border: 2px solid #d88d02;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        margin-left: 40px;
    }
`;
