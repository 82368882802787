import {
    NewOT,
    NewTimeSheet,
    // NewTimeSheetHalfDay,
    // NewTimeSheetDayShiftAndNightShift,
    NewTimeSheetHoliday,
    TimeSheetDayAndNight,
    TimeSheetDetailEarlyLeave,
    TimeSheetDetailNightShift,
    TimeSheetDetailPublicDay,
    TimeSheetDetailTardiness,
    TimeSheetNightAndDay,
    VehicleCalendarComponent,
    WorkScheduleDetailScreen,
} from "@components";
import { appRouteEnums, authRouteEnums } from "@enums";
import { IRoute } from "@interfaces";
import {
    ChangePassword,
    DailyLog,
    Document,
    ForgotPassword,
    Home,
    InstrumentDetail,
    Instruments,
    Inventory,
    InventoryDetail,
    Notification,
    OverTime,
    PageNotFound,
    Profile,
    ResetPassword,
    ReturnSchedule,
    Schedule,
    SignIn,
    Timesheet,
    Vehicles,
    WorkSchedule,
    WeeklySchedule,
    ListDocument,
} from "@pages";
import { AlcoholCheck } from "src/Components/Alcohol";
import { InstrumentCalendarComponent } from "src/Components/InstrumentsDetail";
import { OTDetailView } from "src/Components/OverTime/OTDetailView";
import { TimeSheetDetailHalfDayPaid } from "src/Components/TimeSheetDetail/TimeSheetDetailHalfDayPaid";
import { TimeSheetDetailHolidayPaid } from "src/Components/TimeSheetDetail/TimeSheetDetailHolidayPaid";
import NewTimeSheetEarlyLeave from "src/Components/TimeSheetForm/NewTimeSheetEarlyLeave";
// import NewTimeSheetBereavementLeave from "src/Components/TimeSheetForm/NewTimeSheetBereavementLeave";
// import NewTimeSheetEarlyLeave from "src/Components/TimeSheetForm/NewTimeSheetEarlyLeave";
// import { NewTimeSheetOverTime } from "src/Components/TimeSheetForm/NewTimeSheetOverTime";
import { NewTimeSheetPublicHoliday } from "src/Components/TimeSheetForm/NewTimeSheetPublicHoliday";
import NewTimeSheetTardiness from "src/Components/TimeSheetForm/NewTimeSheetTardiness";
// import NewTimeSheetTardiness from "src/Components/TimeSheetForm/NewTimeSheetTardiness";
import { TimeSheetDayAndNightWrapper, TimeSheetHalfDayWrapper, TimeSheetNightAndDayWrapper, TimeSheetNightShiftWrapper } from "src/Components/TimeSheetForm/page";
import { VehiclesDetail } from "src/Pages/VehiclesDetail";
export const routes: Array<IRoute> = [
    { path: appRouteEnums.PATH_HOME, component: Home, exact: true, isProtected: true },
    {
        path: appRouteEnums.PATH_RETURN_SCHEDULE,
        component: ReturnSchedule,
        exact: true,
        isProtected: true,
    },
    { path: appRouteEnums.PATH_INVENTORY, component: Inventory, exact: true, isProtected: true },
    {
        path: appRouteEnums.PATH_INVENTORY_DETAIL,
        component: InventoryDetail,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_INSTRUMENTS,
        component: Instruments,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_OVERTIME,
        component: OverTime,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_OVERTIME_NEW,
        component: NewOT,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_OVERTIME_EDIT,
        component: NewOT,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_OVERTIME_DETAIL,
        component: OTDetailView,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_INSTRUMENTS_CALENDAR,
        component: InstrumentCalendarComponent,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_INSTRUMENTS_DETAIL,
        component: InstrumentDetail,
        exact: true,
        isProtected: true,
    },
    { path: appRouteEnums.PATH_DOCUMENT, component: Document, exact: true, isProtected: true },
    {
        path: appRouteEnums.PATH_NOTIFICATION,
        component: Notification,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_LIST_DOCUMENT,
        component: ListDocument,
        exact: true,
        isProtected: true,
    },
    { path: appRouteEnums.PATH_TIMESHEET, component: Timesheet, exact: true, isProtected: true },
    // timesheet new form
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW,
        component: NewTimeSheet,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DAYSHIFT_AND_NIGHTSHIFT,
        component: TimeSheetDayAndNightWrapper,
        // component: TimeSheetDayShiftAndNightShift,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DAYSHIFT,
        // component: NewTimeSheetNightShift,
        component:
            //  NewTimeSheetNightShift
            TimeSheetNightShiftWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_NIGHTSHIFT,
        component:
            // NewTimeSheetNightShift,
            TimeSheetNightShiftWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_PAID_TIME_OFF,
        component: NewTimeSheetHoliday,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_HALF_DAY_WORK,
        // component: NewTimeSheetHalfDay,
        component:
            // NewTimeSheetNightShift,
            TimeSheetHalfDayWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_HOLIDAYWORK,
        // component: NewTimeSheetNightShift,
        component:
            // NewTimeSheetNightShift,
            TimeSheetNightShiftWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_ABSENCE,
        component: NewTimeSheetHoliday,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_LEGAL_HOLIDAY,
        component: NewTimeSheetPublicHoliday,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_LEGAL_HOLIDAY,
        component: NewTimeSheetPublicHoliday,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_NIGHTSHIFT_AND_DAYSHIFT,
        component: TimeSheetNightAndDayWrapper,
        // component: TimeSheetDayShiftAndNightShift,
        exact: true,
        isProtected: true,
    },
    // {
    //     path: appRouteEnums.PATH_TIMESHEET_NEW_OVERTIME,
    //     component: NewTimeSheetOverTime,
    //     exact: true,
    //     isProtected: true,
    // },
    // {
    //     path: appRouteEnums.PATH_TIMESHEET_NEW_BEREAVEMENT_LEAVE,
    //     component: NewTimeSheetBereavementLeave,
    //     exact: true,
    //     isProtected: true,
    // },
    // {
    //     path: appRouteEnums.PATH_TIMESHEET_NEW_EARLY_LEAVE,
    //     component: NewTimeSheetEarlyLeave,
    //     exact: true,
    //     isProtected: true,
    // },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_TARDINESS,
        component: NewTimeSheetTardiness,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_EARLY_LEAVE,
        component: NewTimeSheetEarlyLeave,
        exact: true,
        isProtected: true,
    },
    // timesheet detail
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_DAYSHIFT,
        component: TimeSheetDetailNightShift,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_NIGHTSHIFT,
        component: TimeSheetDetailNightShift,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_DAYSHIFT_AND_NIGHTSHIFT,
        component: TimeSheetDayAndNight,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_HOLIDAYWORK,
        component: TimeSheetDetailNightShift,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_PAID_TIME_OFF,
        component: TimeSheetDetailHolidayPaid,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_HALF_DAY_WORK,
        component: TimeSheetDetailHalfDayPaid,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_ABSENCE,
        component: TimeSheetDetailHolidayPaid,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_LEGAL_HOLIDAY,
        component: TimeSheetDetailPublicDay,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW__DETAIL_NIGHTSHIFT_AND_DAYSHIFT,
        component: TimeSheetNightAndDay,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_TARDINESS,
        component: TimeSheetDetailTardiness,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_DETAIL_EARLY_LEAVE,
        component: TimeSheetDetailEarlyLeave,
        exact: true,
        isProtected: true,
    },
    // timesheet edit
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_DAYSHIFT,
        // component: NewTimeSheetNightShift,
        component:
            //  NewTimeSheetNightShift
            TimeSheetNightShiftWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_NIGHTSHIFT,
        component:
            //  NewTimeSheetNightShift
            TimeSheetNightShiftWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_DAYSHIFT_AND_NIGHTSHIFT,
        component: TimeSheetDayAndNightWrapper,
        // component: TimeSheetDayShiftAndNightShift,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_PAID_TIME_OFF,
        component: NewTimeSheetHoliday,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_HALF_DAY_WORK,
        // component: NewTimeSheetHalfDay,
        component:
            // NewTimeSheetNightShift,
            TimeSheetHalfDayWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_HOLIDAYWORK,
        component: TimeSheetNightShiftWrapper,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_ABSENCE,
        component: NewTimeSheetHoliday,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_EDIT_LEGAL_HOLIDAY,
        component: NewTimeSheetPublicHoliday,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW__EDIT_NIGHTSHIFT_AND_DAYSHIFT,
        component: TimeSheetNightAndDayWrapper,
        // component: TimeSheetDayShiftAndNightShift,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_EDIT_TARDINESS,
        component: NewTimeSheetTardiness,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_TIMESHEET_NEW_EDIT_EARLY_LEAVE,
        component: NewTimeSheetEarlyLeave,
        exact: true,
        isProtected: true,
    },
    // others
    { path: appRouteEnums.PATH_SCHEDULE, component: Schedule, exact: true, isProtected: true },
    {
        path: appRouteEnums.PATH_WORK_SCHEDULE,
        component: WorkSchedule,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_WEEKLY_SCHEDULE,
        component: WeeklySchedule,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_WORK_SCHEDULE_LIST_CONSTRUCTION_SITE,
        component: WorkSchedule,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_WORK_SCHEDULE_DETAIL,
        component: WorkScheduleDetailScreen,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_ALCOHOL_CHECK,
        component: AlcoholCheck,
        exact: true,
        isProtected: true,
    },
    { path: appRouteEnums.PATH_DAILY_LOG, component: DailyLog, exact: true, isProtected: true },
    { path: appRouteEnums.PATH_VEHICLES, component: Vehicles, exact: true, isProtected: true },
    {
        path: appRouteEnums.PATH_VEHICLES_CALENDAR,
        component: VehicleCalendarComponent,
        exact: true,
        isProtected: true,
    },
    {
        path: appRouteEnums.PATH_VEHICLES_DETAIL,
        component: VehiclesDetail,
        exact: true,
        isProtected: true,
    },
    { path: authRouteEnums.PATH_SIGNIN, component: SignIn, exact: true },
    { path: authRouteEnums.PATH_FOGOTPASSWORD, component: ForgotPassword, exact: true },
    {
        path: authRouteEnums.PATH_RESETPASSWORD,
        component: ResetPassword,
        exact: true,
    },
    {
        path: authRouteEnums.PATH_CHANGEPASSORD,
        component: ChangePassword,
        exact: true,
        isProtected: true,
    },
    { path: appRouteEnums.PATH_PROFILE, component: Profile, exact: true, isProtected: true },
    { component: PageNotFound, exact: true },
];
