import axiosClient from "./axiosClient";

interface ICategoryParams {
    type: any;
}
export interface IListProductsParams {
    category?: any;
    name?: string;
}
export const categoriesApi = {
    getCategories: (params: ICategoryParams) => {
        const url = `api/category?type=${params.type}`;
        return axiosClient.get(url);
    },
    getListProducts: (params: IListProductsParams) => {
        const url = `api/search-product?categoryId=${params?.category}&name=${params?.name}`;
        return axiosClient.get(url);
    },
    getListDocuments: (params: IListProductsParams) => {
        const url = `api/search-document?category=${params?.category}&name=${params?.name}`;
        return axiosClient.get(url);
    },
};


