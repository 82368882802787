import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IState {
    categories?: any;
    dataResult?: any;
    processing: boolean;
    activeCategory: any;
    idConstuctionInventorySearch?: string;
}

const initialState: IState = {
    processing: false,
    categories: undefined,
    dataResult: undefined,
    activeCategory: false,
    idConstuctionInventorySearch: "",
};
const inventorySlice = createSlice({
    name: "inventory",
    initialState: initialState,
    reducers: {
        setCategory: (state, action: { payload: any }) => {
            state.dataResult = action.payload;
            // state.activeCategory = action.payload.category;
        },
        setActiveCategory: (state, action: { payload: any }) => {
            state.activeCategory = action.payload.category;
        },
        setIdConstuctionInventorySearch: (state, action: { payload: string }) => {
            state.idConstuctionInventorySearch = action.payload;
        }
    },
});

export const selectInventory = (state: RootState) => state.inventory;

export const { setCategory, setActiveCategory, setIdConstuctionInventorySearch } = inventorySlice.actions;

export default inventorySlice.reducer;
