import * as React from "react";

import { FormControl, TextareaAutosize } from "@mui/material";
import { StyledTextArea } from "../AppTextArea/style";
import { Error } from "..";

interface IProps {
    label?: string;
    name?: string;
    value?: string;
    error?: any;
    handleChange: (value: any) => void;
    handleBlur?: (value: any) => void;
    touched?: any;
    placeholder?: string;
    rows?: number;
}

export const AppTextNote = (props: IProps) => {
    const { label, value, error, placeholder, handleChange, touched } = props;
    const handleOnChange = React.useCallback(
        (event: any) => {
            handleChange(event.target.value);
        },
        [handleChange]
    );
    return (
        <StyledTextArea>
            <div className="input__wrapper">
                {label && <div className="input__label">{label}</div>}
                <FormControl sx={{ width: "100%", maxHeight: "40px" }} variant="outlined">
                    <TextareaAutosize
                        value={value}
                        onChange={handleOnChange}
                        // onBlur={handleBlur}
                        // name={name}
                        // autoComplete="off"
                        placeholder={placeholder}
                        maxRows={1}
                    />
                    {!!error && !!touched && <Error>{error}</Error>}
                </FormControl>
            </div>
        </StyledTextArea>
    );
};
