import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    IForgotPasswordForm,
    ILoginForm,
    IChangePasswordForm,
    IResetPasswordForm,
    IUserInfo,
} from "@interfaces";
import { authApi } from "@api";
import { RootState } from ".";
import { updateModal, updateSnackBar } from "./app";

interface ILoginParams {
    dispatch?: any;
    values: ILoginForm;
}
interface ILogout {
    dispatch?: any;
}
interface IForgotPasswordProps {
    dispatch?: any;
    values: IForgotPasswordForm;
}
interface IResetPasswordProps {
    dispatch?: any;
    history?: any;
    values: IResetPasswordForm;
}
interface IChangePasswordProps {
    dispatch?: any;
    values: IChangePasswordForm;
}
export const login = createAsyncThunk(
    "auth/login",
    async (params: ILoginParams, { rejectWithValue }) => {
        try {
            const res = await authApi.login(params.values);
            return res.data as any;
        } catch (err: any) {
            params.dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
            return rejectWithValue(err);
        }
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    async (params: ILogout, { rejectWithValue }) => {
        try {
            const res = await authApi.logout();
            return res;
        } catch (err: any) {
            params.dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
            return rejectWithValue(err);
        }
    }
);

export const forgotPassword = createAsyncThunk(
    "auth/fogot-password",
    async (params: IForgotPasswordProps, { rejectWithValue }) => {
        try {
            const res = await authApi.fogotPassword(params.values);
            params.dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: res.data.message,
                })
            );
            return res;
        } catch (err: any) {
            params.dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
            return rejectWithValue(err);
        }
    }
);
export const resetPassword = createAsyncThunk(
    "auth/reset-password",
    async (params: IResetPasswordProps, { rejectWithValue }) => {
        try {
            const res = await authApi.resetPassword(params.values);
            params.dispatch(
                updateModal({
                    messageType: "success",
                    message: res.data.message,
                })
            );

            return res;
        } catch (err: any) {
            params.dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
            return rejectWithValue(err);
        }
    }
);
export const changePassword = createAsyncThunk(
    "auth/change-password",
    async (params: IChangePasswordProps, { rejectWithValue }) => {
        try {
            const res = await authApi.changePassword(params.values);
            params.dispatch(
                updateModal({
                    messageType: "success",
                    message: res.data.message,
                })
            );
            return res;
        } catch (err: any) {
            params.dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
            return rejectWithValue(err);
        }
    }
);
export const getInfo = createAsyncThunk(
    "auth/getInfo",
    async (params: any, { rejectWithValue }) => {
        try {
            const res = await authApi.getInfo();
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getListGlobalDocument = createAsyncThunk(
    "auth/getListGlobalDocument",
    async (params: any, { rejectWithValue }) => {
        try {
            const res = await authApi.getListGlobalDocument();
            return res.data.data;
        } catch (err) {
            return rejectWithValue(err);
        };
    }
);
export const updateAvatar = createAsyncThunk(
    "auth/update-avatar",
    async (params: any, { rejectWithValue }) => {
        try {
            const res = await authApi.updateAvatar(params.formData);
            params.dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: res.data.message,
                })
            );
            return res.data;
        } catch (err: any) {
            params.dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
            return rejectWithValue(err);
        }
    }
);

interface IState {
    isRemember: boolean;
    passwordRemember?: string;
    emailRemember?: string;
    isLoading: boolean;
    error: string;
    tokenInfoAuth: string;
    userInfo: IUserInfo | null;
    permissions: string[];
    modal?: string;
    notiId?: any;
    organiztionChart?: any;
    data?: any;
    idConstuctionEmployeeSearch: string;
}

const initialState: IState = {
    isRemember: false,
    passwordRemember: "",
    isLoading: false,
    error: "",
    tokenInfoAuth: "",
    userInfo: null,
    permissions: [],
    idConstuctionEmployeeSearch: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setRemember: (state, action: PayloadAction<boolean>) => {
            state.isRemember = action.payload;
        },
        setRememberMe: (state, action: PayloadAction<any>) => {
            state.emailRemember = action.payload.email;
            state.passwordRemember = action.payload.password;
        },
        resetAuth: (state) => {
            state.tokenInfoAuth = initialState.tokenInfoAuth;
        },
        updateNotiId: (state, action: PayloadAction<any>) => {
            state.notiId = action.payload;
        },
        setIdConstuctionEmployeeSearch: (state, action: { payload: string }) => {
            state.idConstuctionEmployeeSearch = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(login.fulfilled, (state, action: { payload: any }) => {
            state.userInfo = action.payload.data.user;
            state.tokenInfoAuth = action.payload.data.token;
            state.isLoading = false;
        });

        builder.addCase(login.rejected, (state) => {
            state.userInfo = null;
            state.isLoading = false;
            state.passwordRemember = "";
            state.emailRemember = "";
        });
        builder.addCase(logout.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.userInfo = initialState.userInfo;
            state.tokenInfoAuth = initialState.tokenInfoAuth;
            state.isLoading = false;
        });
        builder.addCase(logout.rejected, (state) => {
            state.isLoading = false;
        });
        // forgot password
        builder.addCase(forgotPassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(forgotPassword.fulfilled, (state) => {
            state.isLoading = false;
        });

        builder.addCase(forgotPassword.rejected, (state) => {
            state.isLoading = false;
        });
        // reset password
        builder.addCase(resetPassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(resetPassword.fulfilled, (state) => {
            state.isLoading = false;
            state.passwordRemember = "";
        });
        builder.addCase(resetPassword.rejected, (state) => {
            state.isLoading = false;
        });
        // change password
        builder.addCase(changePassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(changePassword.fulfilled, (state) => {
            state.isLoading = false;
            state.passwordRemember = "";
        });
        builder.addCase(changePassword.rejected, (state) => {
            state.isLoading = false;
        });
        // change Avatar
        builder.addCase(updateAvatar.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateAvatar.fulfilled, (state, action: { payload: any }) => {
            state.isLoading = false;
            state.userInfo = {
                ...state.userInfo,
                avatar: action.payload.avatar,
            };
        });
        builder.addCase(updateAvatar.rejected, (state) => {
            state.isLoading = false;
        });
        // getInfo
        builder.addCase(getInfo.fulfilled, (state, action: { payload: any }) => {
            state.userInfo = action.payload.user;
            state.organiztionChart = action.payload.organizationChart;
        });
        //getListGlobalDocument
        builder.addCase(getListGlobalDocument.fulfilled, (state, action: { payload: any }) => {
            state.data = action.payload;

        });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { setRemember, resetAuth, setRememberMe, updateNotiId, setIdConstuctionEmployeeSearch } = authSlice.actions;

export default authSlice.reducer;
