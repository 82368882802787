import { appRouteEnums } from "@enums";
import React from "react";
import { AppIconButton, AppModal, AppTopBar, CircularLoader } from "../Common";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    resetListDocument,
    // selectAuth,
    selectListDocument,
    updateListDocument,
    updateNumberListDocument,
    useAppSelector,
} from "@redux";
import { StyledListDocumentComponent, ListDocumentItem } from ".";
import active_arrow_down from "@assets/Icons/active_arrow_down.svg";
import {
    DOCUMENT_EXCEL_FAILED_TO_LOAD,
    DOCUMENT_FAILED_TO_LOAD,
    LABEL_LIST_DOCUMENT,
    MESSAGE_DATA_NOT_FOUND,
    MESSAGE_LOADING_PDF,
} from "@configs";
import { listDocumentApi } from "@api";
import { StyledIframe, StyledPDF } from "../Document";
import { Document, Page } from "react-pdf";
import {
    converNumberToArrayOfInterger,
    isLinkExel,
    linkDocument,
    useWindowDimensions,
} from "@utils";
import { saveAs } from "file-saver";
import { TitleMenuComponent } from "../TitleMenuComponent";

export const ListDocumentView = () => {
    const listDocument = useAppSelector(selectListDocument);

    // const user = useAppSelector(selectAuth);
    // const { userInfo } = user;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(1);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loadMore, setLoadMore] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);
    const [openPDF, setOpenPDF] = React.useState<boolean>(false);
    const [linkPdf, setLinkPdf] = React.useState<any>("");
    const [numPages, setNumPages] = React.useState(null);
    const width = useWindowDimensions().width;

    const handleOnSelectListDocument = async (path: any, title: any, id: any) => {
        try {
            const checkRes = await listDocumentApi.readListDocument({
                box_document_id: id,
            });
            if (checkRes.status === 200) {
                dispatch(
                    updateNumberListDocument(
                        listDocument.numberListDocument === 0
                            ? listDocument.numberListDocument
                            : listDocument.numberListDocument - 1
                    )
                );
                setReload(!reload);
            }
            if (path.endsWith(".xlsx")) {
                const response = await fetch(path);
                const blob = await response.blob();
                saveAs(blob, `${title}.xlsx`);
                setReload(!reload);
            } else {
                handleOpenPDF(path);
                setReload(!reload);
            }
        } catch (error) {}
    };

    const handleDeleteListDocument = async (id: any) => {
        try {
            await listDocumentApi.deleteListDocument({
                box_document_id: [id],
            });
            setReload(!reload);
        } catch (err) {}
    };

    const handleOpenPDF = (value: any) => {
        //  window.location.href = '';
        if (width <= 980) {
            window.location.href = linkDocument(value);
        }
        setLinkPdf(value);
        setOpenPDF(true);
    };

    const handleClosePDF = () => {
        setOpenPDF(false);
        setLinkPdf("");
    };

    const fetchListDocument = async () => {
        try {
            setLoading(true);
            const res = await listDocumentApi.viewListDocument(page);
            if (page === 1) {
                dispatch(resetListDocument());
            }
            setLoading(false);
            dispatch(updateListDocument(res.data.data.documents));
            dispatch(updateNumberListDocument(res.data.data.not_read));
            if (
                res.data.data.documents.length < 10 &&
                res.data.data.documents.length < res.data.data.total
            ) {
                setLoadMore(false);
            } else {
                setLoadMore(true);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        setPage(page + 1);
    };
    React.useEffect(() => {
        fetchListDocument();
        return () => {
            //    do something cleanup
        };
    }, [page, reload]);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    return (
        <StyledListDocumentComponent>
            <AppTopBar />
            <CircularLoader loading={loading} />
            {width >= 981 && (
                <AppModal open={openPDF} handleClose={handleClosePDF}>
                    <StyledPDF>
                        {linkPdf ? (
                            isLinkExel(linkPdf) ? (
                                <p className="failePDF">{DOCUMENT_EXCEL_FAILED_TO_LOAD}</p>
                            ) : width >= 991 ? (
                                <StyledIframe
                                    heightCalculationMethod="lowestElement"
                                    src={linkDocument(linkPdf)}
                                    log
                                    scrolling={true}
                                    autoResize={false}
                                />
                            ) : (
                                <Document
                                    file={linkDocument(linkPdf)}
                                    options={{ workerSrc: "/pdf.worker.js" }}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    error={DOCUMENT_FAILED_TO_LOAD}
                                    loading={MESSAGE_LOADING_PDF}
                                >
                                    {numPages &&
                                        converNumberToArrayOfInterger(numPages).map(
                                            (page, index) => <Page pageNumber={index} />
                                        )}
                                </Document>
                            )
                        ) : (
                            <p className="failePDF">{DOCUMENT_FAILED_TO_LOAD}</p>
                        )}
                    </StyledPDF>
                </AppModal>
            )}
            <div className="wrapperForm-top">
                <p className="top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
            </div>
            <div className="wrapperForm">
                <p className="top-icon">
                    <TitleMenuComponent text={LABEL_LIST_DOCUMENT} />
                </p>
            </div>
            <div className="noti">
                <div className="noti__wrapper">
                    <div className="noti__content">
                        {listDocument.listDocument?.length
                            ? listDocument.listDocument.map((noti, index) => (
                                  <div key={index}>
                                      <ListDocumentItem
                                          handleOnSelectListDocument={() =>
                                              handleOnSelectListDocument(
                                                  noti?.path,
                                                  noti?.title,
                                                  noti?.id
                                              )
                                          }
                                          data={noti}
                                          handleDeleteListDocument={() => {
                                              handleDeleteListDocument(noti?.id);
                                          }}
                                      />
                                  </div>
                              ))
                            : !loading && (
                                  <p className="messageNotFound">{MESSAGE_DATA_NOT_FOUND}</p>
                              )}
                    </div>
                </div>
            </div>
            {/* <div className="wrapperLoading">
                <CircularLoader loading={loading} size={15} type="fullContent" />
            </div> */}
            {!loading && loadMore && (
                <p className="viewMore">
                    <span className="viewMore__text" onClick={() => handleLoadMore()}>
                        続きを見る
                    </span>
                    <img
                        className="viewMore__icon"
                        src={active_arrow_down}
                        alt="icon"
                        onClick={() => handleLoadMore()}
                    />
                </p>
            )}
        </StyledListDocumentComponent>
    );
};
