import { ellipsisText } from "@utils";
import moment from "moment";
import React from "react";

interface IProps {
  data?: any;
}
export const ItemComponent = (props: IProps) => {
  const { data } = props;

  const startDate =
    data?.start_reservation && moment(data?.start_reservation).format("YYYY-MM-DD");
  const endDate = data?.end_reservation && moment(data?.end_reservation).format("YYYY-MM-DD");
  const oldStartDate =
    data?.pre_start_reservation && moment(data?.pre_start_reservation).format("YYYY-MM-DD");
  const oldEndDate =
    data?.pre_end_reservation && moment(data?.pre_end_reservation).format("YYYY-MM-DD");
  const date = data?.date && moment(data?.date).format("YYYY-MM-DD");
  const displayDate = data?.date && moment(data?.date).format("YYYY/MM/DD HH:MM");

  const [showDes, setShowDes] = React.useState(false);
  return (
    <div className="item">
      <div className="item__top">
        <div>
          <p className="item__title" style={{ whiteSpace: "pre-line" }}>
            {data?.note}: {data?.name}
          </p>

          {data?.returning_inventory !== null && (
            <>
              <p className="item__amount">+ {data?.returning_inventory}</p>
              <p className="item__amount">合計 {data?.quantity}</p>
            </>
          )}

          {data?.taking_inventory !== null && (
            <>
              <p className="item__amount">- {data?.taking_inventory}</p>
              <p className="item__amount">合計 {data?.quantity}</p>
            </>
          )}
          <p className="item__amount">
            {data.last_name} {data.first_name}
          </p>
          <p className="item__amount">現場名: {data.construction_name}</p>
          <p className="item__amount">{displayDate}</p>
        </div>

        <span className="item__code">
          <div>
            {oldStartDate && oldStartDate !== startDate && (
              <div style={{ textDecoration: "line-through" }}>{oldStartDate}</div>
            )}
            {startDate || date}
            <p style={{ fontSize: 18 }}>{endDate && "≀"}</p>
          </div>
          <div>
            {oldEndDate && oldEndDate !== endDate && (
              <div style={{ textDecoration: "line-through" }}>{oldEndDate}</div>
            )}
            {endDate}
            {/* <p>{endDate && " 返却"}</p> */}
          </div>
        </span>
      </div>
      {data?.desc && (
        <p className="item__des" onClick={() => setShowDes(!showDes)}>
          {!showDes ? ellipsisText(data.description, 28) : data.description}
        </p>
      )}
    </div>
  );
};
