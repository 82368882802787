import React from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { StyledReturnScheduleView, ReturnScheduleItem } from ".";
import { TitleMenuComponent, AppModal, AppAlert, CircularLoader, AppIconButton } from "..";
import { returnScheduleItem } from "@interfaces";
import { returnSchedule } from "@api";
import { appRouteEnums, menuTitleEnums } from "@enums";
import { updateSnackBar } from "@redux";
import cool_blue_frame from "@assets/Icons/cool_blue_frame.svg";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";

export const ReturnScheduleView = () => {
    const [selected, setSelected] = React.useState<number>(-1);
    const [listVehicles, setListVehicles] = React.useState<Array<any>>();
    const [success, setSuccess] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [message, setMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    // hooks
    const dispatch = useDispatch();
    const history = useHistory();
    //what: fetch api list borrowing
    const fetchListItemBorrowingApi = async () => {
        setLoading(true);
        try {
            const res = await returnSchedule.returnListItemBorrowed();
            setLoading(false);
            setListVehicles(res.data.data);
        } catch (error: any) {
            setLoading(false);
            updateSnackBar({
                messageType: "error",
                message: error.response?.data?.message,
            });
        }
    };
    // what: fetch api return item
    const fetchReturnItemApi = async (id: any, construction_id: any) => {
        setLoading(true);
        try {
            const res = await returnSchedule.returnItem({
                id,
                quantity: 1,
                construction_id: construction_id,
            });
            setLoading(false);
            setMessage(res.data?.message);
            setSuccess(true);
        } catch (error: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error.response?.data?.message,
                })
            );
        }
    };
    const viewItemCalendar = (values: any) => {
        history.push(`${values.itemType}/calendar/${values.itemId}`);
    };
    // events
    const handleClick = (id: any) => {
        setSelected(id);
    };
    const handleOnClickReturn = (id: any, construction_id: any) => {
        fetchReturnItemApi(id, construction_id);
    };
    const handleCloseModal = () => {
        setReload(!reload);
        setSuccess(false);
    };
    React.useEffect(() => {
        fetchListItemBorrowingApi();
    }, [reload]);
    return (
        <StyledReturnScheduleView>
            <CircularLoader loading={loading} />
            <AppModal open={success}>
                <AppAlert
                    icon={<img src={cool_blue_frame} alt="icon" />}
                    text={message}
                    handleClose={handleCloseModal}
                />
            </AppModal>

            <div className="returnSchedule">
                <p
                    className="returnSchedule__icon"
                    onClick={() => history.push(appRouteEnums.PATH_HOME)}
                >
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
                <div className="returnSchedule__title">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_RETURN_SCHEDULE} />
                </div>
                <div className="returnSchedule__content">
                    <Grid container spacing={4}>
                        {listVehicles &&
                            listVehicles.map((item: returnScheduleItem, index: number) => (
                                <Grid key={index} item xs={12} md={4}>
                                    <ReturnScheduleItem
                                        data={item}
                                        handleClick={handleClick}
                                        selected={selected}
                                        setSelected={setSelected}
                                        handleOnClickReturn={handleOnClickReturn}
                                        viewItemCalendar={viewItemCalendar}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </div>
            </div>
        </StyledReturnScheduleView>
    );
};
