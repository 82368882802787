import React from "react";
import { returnScheduleItem } from "@interfaces";

import { StyledReturnScheduleItem } from ".";
import { Grid } from "@mui/material";
import { GetInIcon } from "@assets";

import { AppAlertConfirm, AppModal, AppIconButton } from "..";
import clsx from "clsx";
import { linkImage } from "@utils";
import moment from "moment";

interface IProps {
    data: returnScheduleItem;
    handleClick: (index: any) => void;
    selected: number;
    setSelected: React.Dispatch<React.SetStateAction<number>>;
    handleOnClickReturn: (id: any, construction_id?: any) => void;
    viewItemCalendar: (values: any) => void;
}

export const ReturnScheduleItem = (props: IProps) => {
    const { data, handleClick, selected, setSelected, handleOnClickReturn, viewItemCalendar } =
        props;
    // component state
    const [show, setShow] = React.useState<boolean>(false);
    // events
    const handleAccept = () => {
        setShow(false);
        handleOnClickReturn(data.id, data?.construction?.id);
    };
    const handleReject = () => {
        setShow(false);
        setSelected(-1);
    };
    const nowDate = moment(new Date()).format("YYYY-MM-DD");
    const isNowDateBeforeTo = moment(nowDate).isAfter(data?.to, "day");

    // F2-012 start
    const isNowDateBeforeFrom = moment(nowDate).isBefore(data?.from, "day");

    const returnConfirm = (
      <AppModal open={show}>
        <AppAlertConfirm
          handleAccept={handleAccept}
          handleReject={handleReject}
          title={data?.title}
          from={data?.from}
          to={data?.to}
          icon={linkImage(data?.image?.path)}
          text="返却しますか？"
        />
      </AppModal>
    );

    const cancelConfirm = (
      <AppModal open={show}>
        <AppAlertConfirm
          handleAccept={handleAccept}
          handleReject={handleReject}
          title={data?.title}
          from={data?.from}
          to={data?.to}
          icon={linkImage(data?.image?.path)}
          text="取消しますか？"
        />
      </AppModal>
    );
    // F2-012 end

    return (
        <StyledReturnScheduleItem>

          {/* F2-012 start */}

          {isNowDateBeforeFrom ? cancelConfirm : returnConfirm}

            {/* <AppModal open={show}>
                <AppAlertConfirm
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                    title={data?.title}
                    from={data?.from}
                    to={data?.to}
                    icon={linkImage(data?.image?.path)}
                    text="返却しますか？"
                />
            </AppModal> */}

           {/* F2-012 end */}
            <div className={clsx("wrapperItem", selected === data.id ? "active" : "")}>
                <Grid container>
                    <Grid
                        item
                        xs={2}
                        onClick={() =>
                            viewItemCalendar({
                                itemType: data?.item_type,
                                itemId: data?.item_id,
                            })
                        }
                    >
                        <div className="item__icon">
                            <img className="image" src={linkImage(data?.image?.path)} alt="icon" />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        onClick={() =>
                            viewItemCalendar({
                                itemType: data?.item_type,
                                itemId: data?.item_id,
                            })
                        }
                    >
                        <div className="item__content">
                            <p className="text">{data?.title}</p>
                            <p className="subText">
                                <span>{data?.from}</span>～<span className="code2">{data?.to}</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div
                            className="item__btn"
                            onClick={() => {
                                handleClick(data?.id);
                                setShow(true);
                            }}
                        >
                            <AppIconButton icon={<GetInIcon />} color="primary" />
                        </div>
                    </Grid>
                    {isNowDateBeforeTo && (
                        <Grid item xs={1} display={"flex"} alignItems={"center"} color={"red"}>
                            遅延
                        </Grid>
                    )}
                </Grid>
            </div>
        </StyledReturnScheduleItem>
    );
};
