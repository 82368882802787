import axiosClient from "./axiosClient";

export const instrumentApi = {
    getViewListProducts: (params: any) => {
        const url = `/api/search-instrument?categoryId=${params.category}&name=${params.name}`;
        return axiosClient.get(url);
    },
    viewInstrumentDetail: (params: any) => {
        const url = `api/instrumentItem-detail/${params.id}`;
        return axiosClient.get(url);
    },
    viewInstrumentListItem: (params: any) => {
    const url = `/api/instrument-item/${params.id}/?category=${params.category}`;
        return axiosClient.get(url);
    },
    instrumentItemCalendar: (params:any)=>{
        const url = `/api/instrumentItem-history/${params.id}`;
        return axiosClient.get(url);
    },
    instrumentBorrowItem: (params: any,id:any)=>{
        const url = `/api/borrowing-instrumentItem/${id}`;
        return axiosClient.post(url,params);
    },
    updateBorrowItem: (params:any,id: any)=>{
        const url = `/api/borrowing/${id}`;
        return axiosClient.put(url,params);
    },
    instrumentDetailBorrowing: (params: any) => {
        const url = `/api/instrumentItem-history/${params.id}/${params.itemId}`;
        return axiosClient.get(url);
    }
};
