import React from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { StyledInventoryView, InventoryCatetoryComponent, InventoryListItemComponent } from ".";
import { TitleMenuComponent, AppInput, AppTopBar, AppIconButton } from "..";
import { SearchIcon } from "@assets";
import { categoriesApi } from "@api";
import { updateSnackBar, resetAuth, setCategory } from "@redux";
import { API_CATEGORY_TYPE_INVENTORY, PLACEHOLDER_SEARCH } from "@configs";
import { useWindowDimensions } from "@utils";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { useHistory, useLocation } from "react-router-dom";

export const InventoryView = () => {
    // component state
    const [inputValue, setInputValue] = React.useState<string>("");
    const [categories, setCategories] = React.useState<Array<any>>();
    const [listProducts, setListProducts] = React.useState<any>();
    const [subCategoryId, setSubCategoryId] = React.useState<any>("");
    const [loading, setLoading] = React.useState<boolean>(true);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const messagesEndRef = React.useRef<any>(null);
    const [categoryViewMobile, setCategoryViewMobile] = React.useState<boolean>(true);
    // hooks
    const dispatch = useDispatch();
    const width = useWindowDimensions().width;
    const history = useHistory();
    //What: scroll to bottom to view data results when device is mobile
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    //What: call api get categories
    React.useEffect(() => {
        const fetchCategoriesApi = async () => {
            try {
                const response = await categoriesApi.getCategories({
                    type: API_CATEGORY_TYPE_INVENTORY,
                });
                setCategories(response.data.data);
            } catch (err: any) {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: err.response.data.message,
                    })
                );
                dispatch(resetAuth());
            }
        };
        fetchCategoriesApi();
    }, []);
    //what: call api get list products
    const fetchListProducts = async (params: any) => {
        setLoading(true);
        try {
            const response = await categoriesApi.getListProducts({
                category: params.category || "",
                name: params.name || "",
            });
            setLoading(false);
            setListProducts(response.data.data);
        } catch (err: any) {
            setListProducts(undefined);
            setLoading(false);
        }
    };

    //what: call api after re-render
    //how: when sub category id is updated, this page is re-render
    React.useEffect(() => {
        fetchListProducts({
            category: subCategoryId,
        });
    }, [subCategoryId]);
    //what: events
    const handleOnChange = (value: string) => {
        setInputValue(value);
    };
    //what: call api in search form
    const handleOnSearchSubmit = () => {
        setCategoryViewMobile(false);
        fetchListProducts({
            name: inputValue,
        });
        setSelectedIndex(-1);
        if (width < 768) {
            scrollToBottom();
        }
        dispatch(setCategory({ category: "", subCategory: "" }));
    };
    //what: select a subCategory ID
    const handleSelectSubCategory = (id: number, categoryId: number) => {
        setSubCategoryId(id);
        setCategoryViewMobile(false);
        if (width < 768) {
            scrollToBottom();
        }
        setInputValue("");
        history.push(`/inventory?categoryId=${categoryId}&subCategoryId=${id}`);
    };
    //what: active select category
    const handleClick = (index: any) => {
        if (selectedIndex === index) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
        }
    };
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");

    return (
        <StyledInventoryView>
            <AppTopBar />
            <div className="wrapperInventory">
                <div className="inventory">
                    <div className="inventory__top">
                        <p
                            className="newOT__top-icon"
                            onClick={() => history.push(appRouteEnums.PATH_HOME)}
                        >
                            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                        </p>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={8}>
                                <div className="inventory__top-title">
                                    <TitleMenuComponent text={menuTitleEnums.TITLE_INVENTORY} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="inventory__top-searchForm">
                                    <AppInput
                                        value={inputValue}
                                        iconStart={<SearchIcon />}
                                        handleChange={handleOnChange}
                                        placeholder={PLACEHOLDER_SEARCH}
                                        disabled={false}
                                        onClick={handleOnSearchSubmit}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="inventory__content visible-pc">
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <div className="category">
                                    {categories &&
                                        categories.map((category, index) => (
                                            <div key={category.id}>
                                                <InventoryCatetoryComponent
                                                    category={category}
                                                    checked={
                                                        String(categoryId) ===
                                                            String(category.id) ||
                                                        index === selectedIndex
                                                    }
                                                    onClick={() => {
                                                        handleClick(index);
                                                    }}
                                                    handleSelectSubCategory={
                                                        handleSelectSubCategory
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <div className="listItem" ref={messagesEndRef}>
                                    <InventoryListItemComponent
                                        loading={loading}
                                        products={listProducts}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="visible-mobile">
                        {categoryViewMobile ? (
                            <div className="category">
                                {categories &&
                                    categories.map((category, index) => (
                                        <div key={category.id}>
                                            <InventoryCatetoryComponent
                                                category={category}
                                                checked={
                                                    String(categoryId) === String(category.id) ||
                                                    index === selectedIndex
                                                }
                                                onClick={() => {
                                                    handleClick(index);
                                                }}
                                                handleSelectSubCategory={handleSelectSubCategory}
                                            />
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <div>
                                <div
                                    style={{ width: "100%", textAlign: "left", paddingTop: "2rem" }}
                                    onClick={() => setCategoryViewMobile(true)}
                                >
                                    <img src={mobile_arrow_left} alt="icon" />
                                </div>
                                <InventoryListItemComponent
                                    loading={loading}
                                    products={listProducts}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </StyledInventoryView>
    );
};
