import { Collapse } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import { StyledInventoryCategory } from "..";
import { SmallArrowDownIcon, SmallArrowRightIcon } from "@assets";
import { setCategory } from "@redux";

interface IProps {
    category: any;
    checked: boolean;
    onClick: () => void;
    handleSelectSubCategory: (values: any, categoryId: number) => void;
}
export const InstrumentCatetoryComponent = (props: IProps) => {
    // props
    const { category, checked, onClick, handleSelectSubCategory } = props;

    // hooks
    const dispatch = useDispatch();

    //events
    const handleOpenCollapse = (category: string) => {
        dispatch(
            setCategory({
                category,
            })
        );
    };
    const getDataBySubCategory = (category: string, subCategory: string) => {
        dispatch(
            setCategory({
                category,
                subCategory,
            })
        );
    };
    return (
        <StyledInventoryCategory activeCollapse={checked}>
            <div className="categoryItem">
                <div className="collapse__header" onClick={onClick}>
                    <p className="collapse__header-title">{category?.title}</p>
                    {!checked ? (
                        <span className="collapse__header-icon">
                            <SmallArrowRightIcon />
                        </span>
                    ) : (
                        <span
                            className="collapse__header-icon"
                            onClick={() => handleOpenCollapse("")}
                        >
                            <SmallArrowDownIcon />
                        </span>
                    )}
                </div>
                <Collapse in={checked}>
                    <div className="collapse__content">
                        {category?.subCategory?.map((item: any) => (
                            <p
                                className="collapse__subText"
                                key={item?.id}
                                onClick={() => {
                                    getDataBySubCategory(category.title, item.title);
                                    handleSelectSubCategory(
                                        {
                                            id: item.id,
                                            title: category.title,
                                            subTitle: item.title,
                                        },
                                        category.id
                                    );
                                }}
                            >
                                {item?.title}
                            </p>
                        ))}
                    </div>
                </Collapse>
            </div>
        </StyledInventoryCategory>
    );
};
