import React from "react";
import { ListDocumentView } from "@components";

export const ListDocument = () => {
    return (
        <div>
            <ListDocumentView />
        </div>
    );
};
