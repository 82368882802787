import { combineReducers } from "redux";
import { AnyAction, Reducer } from "@reduxjs/toolkit";

import appReducer from "./app";
import menuReducer from "./menu";
import authReducer from "./auth";
import inventoryReducer from "./inventory";
import vehicleReducer from "./vehicles";
import instrumentReducer from "./instruments";
import notificationReducer from "./notification";
import listDocumentReducer from "./listDocument";
import workScheduleReducer from "./workSchedule";

export * from "./app";
export * from "./menu";
export * from "./auth";
export * from "./inventory";
export * from "./vehicles";
export * from "./instruments";
export * from "./notification";
export * from "./listDocument";
export * from "./workSchedule";

const productReducer = combineReducers({
    app: appReducer,
    menu: menuReducer,
    auth: authReducer,
    inventory: inventoryReducer,
    vehicle: vehicleReducer,
    instrument: instrumentReducer,
    notification: notificationReducer,
    listDocument: listDocumentReducer,
    workSchedule: workScheduleReducer,
});

export type RootState = ReturnType<typeof productReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === "RESET") {
        // reset state
        state = {} as RootState;
        // reset local storage
        localStorage.clear();
    }
    return productReducer(state, action);
};
export default rootReducer;
