import axiosClient from "./axiosClient";

export const listDocumentApi = {
    viewListDocument: (page: any) => {
        const url = `/api/get-list-box-document?page=${page}&limit=10`;
        return axiosClient.get(url);
    },
    viewListDocumentProfile: () => {
        const url = `/api/get-list-box-document`;
        return axiosClient.get(url);
    },
    deleteListDocument: (body: any) => {
        const url = `/api/delete-box-document`;
        return axiosClient.post(url, body);
    },
    readListDocument: (body: any) => {
        const url = `/api/read-box-document`;
        return axiosClient.post(url, body);
    },
};