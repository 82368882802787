
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IState {
    listDocument: Array<any>;
    numberListDocument: number;
}
const initialState: IState = {
    listDocument: [],
    numberListDocument: 0
};

const listDocumentSlice = createSlice({
    name: "listDocument",
    initialState: initialState,
    reducers: {
        updateListDocument: (state, action: { payload: any }) => {
            state.listDocument = [...state.listDocument, ...action.payload];
        },
        resetListDocument: (state) => {
            state.listDocument = [];
        },
        updateNumberListDocument: (state, action: { payload: number }) => {
            state.numberListDocument = action.payload;
        }

    },
});
export const selectListDocument = (state: RootState) => state.listDocument;
export const { updateListDocument, resetListDocument, updateNumberListDocument } = listDocumentSlice.actions;
export default listDocumentSlice.reducer;