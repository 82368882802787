import React from "react";
import { Switch } from "react-router-dom";

import "./App.css";
import { routes } from "@configs";
import { ComponentAppRoute, AppSnackbar } from "@components";
import { IRoute } from "@interfaces";
import { useAppSelector, useAppDispatch } from "./Redux/store";
import firebase from "./firebaseConfig";
import { selectAuth, updateNumberNoti, selectNoti, updateNotiId, resetNoti } from "@redux";
import { notiApi } from "@api";
import { fetchVehiclesData, fetchVehiclesCategories } from "./Redux/Slices/vehicles";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    const user = useAppSelector(selectAuth);
    const tokenUser = user?.tokenInfoAuth;
    const notifications = useAppSelector(selectNoti);
    // hooks
    //const dispatch = useDispatch();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (tokenUser) {
            dispatch(fetchVehiclesData() as any);
            dispatch(fetchVehiclesCategories() as any);
        }
    }, [tokenUser, dispatch]);

    // fetch api list noti
    const fetchListNoti = async () => {
        dispatch(resetNoti());
        const res = await notiApi.viewListNotification({ page: 1, status: "" });
        dispatch(updateNumberNoti(res.data.data.amount));
    };
    React.useEffect(() => {
        if (tokenUser) fetchListNoti();
        return () => {
            // do something cleanup
        };
    }, [tokenUser]);
    let message;
    if (firebase.messaging.isSupported()) {
        message = firebase.messaging();
    }
    React.useEffect(() => {
        if (tokenUser) {
            if (firebase.messaging.isSupported()) {
                message
                    .getToken()
                    .then(() => {
                        return message.getToken();
                    })
                    .then((tokenDevice) => {
                        if (tokenUser) {
                            // Send the token to server and update the UI if necessary
                            notiApi
                                .storeTokenDevice({ token: tokenDevice })
                                .then((res) => dispatch(updateNotiId(res.data.id)));
                        }
                    });
            }
        }
    }, [tokenUser]);
    if (firebase.messaging.isSupported()) {
        message.onMessage(() => {
            if (tokenUser) {
                dispatch(updateNumberNoti(notifications.numberNoti + 1));
            }
        });
    }
    return (
        <div className="App">
                <Switch>
                    {routes.map((e: IRoute, key) => (
                        <ComponentAppRoute key={key} {...e} />
                    ))}
                </Switch>
                <AppSnackbar />
            <ToastContainer />
        </div>
    );
}

export default App;
