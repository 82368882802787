import { useEffect, useRef, useState, memo } from "react";
import { StyledTableViewWeeklySchedule, StyledTableWrapper } from "./styles";
import { getDayIndexMoment, getDaysWeeksArrayMoment } from "@utils";
import clsx from "clsx";
import { selectAuth, useAppSelector } from "@redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CircularLoader } from "../Common";
import { weeklyScheduleApi } from "src/Api/weekly-schedule";
import moment from "moment";
import { Button, Divider } from "@mui/material";
import { AppTextNote } from "../Common/AppTextArea copy";
import { IoIosArrowDown } from "react-icons/io";
import { ImArrowDown2, ImArrowUp2 } from "react-icons/im";

interface IProps {
    handleItemOnClick?: (values: any) => void;
    handleOpenViewDetail?: (id: any) => void;
    isViewDetail?: boolean;
    data?: any;
    date?: any;
    listHolidayDate?: any;
    sortName?: (values: any) => void;
    worksInConstructionParams: { page: number };
    setSortNameId?: any;
}

export const ViewGraphCalendar = memo((props: IProps) => {
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    const {
        data,
        handleOpenViewDetail,
        date,
        listHolidayDate,
        worksInConstructionParams,
        setSortNameId,
    } = props;
    const [a, setA] = useState<any>();
    const [checkDate, setCheckDate] = useState<any>();

    const form = useForm<any>();
    const [showSubmitButton, setShowSubmitButton] = useState({});
    const [loading, setLoading] = useState<boolean>(false);
    const [checkColor, setCheckColor] = useState<any>("white");

    const lastRowRef = useRef<any>(null);
    const tableList = useRef<any>();
    const tableRef = useRef<any>(null);
    useEffect(() => {
        const timeout = setTimeout(() => {
            const dayIndex = getDayIndexMoment(checkDate);
            const element =
                tableRef.current.querySelectorAll(".calendar td")[
                    window.innerWidth >= 500 ? dayIndex - 2 : dayIndex - 1
                ];
            element?.scrollIntoView({ behavior: "smooth", inline: "start", offsetLeft: -100 });
        }, 2000);
        () => {
            clearTimeout(timeout);
        };
    }, [checkDate, worksInConstructionParams]);

    useEffect(() => {
        if (worksInConstructionParams?.page > 1) {
            const timeout = setTimeout(() => {
                // Tính toán vị trí của phần tử tiếp theo cần cuộn đến
                const itemPerPage = 10;
                const nextIndex = itemPerPage * (worksInConstructionParams?.page - 1);

                const elements = tableList.current.querySelectorAll(".tbListName");
                const nextElement = elements[nextIndex + 1];

                if (nextElement) {
                    nextElement.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });
                }
            }, 2000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [worksInConstructionParams.page]);
    const sortName = () => {
        if (checkColor === "white") {
            const sortedData = [...a]; // lưu lại mảng ban đầu
            const firstItem = sortedData.shift(); // lấy phần tử đầu tiên ra khỏi mảng
            const setSortName = sortedData.sort((a, b) => a.name.localeCompare(b.name)); // sắp xếp các phần tử còn lại
            setSortName.unshift(firstItem); // thêm phần tử đầu tiên vào vị trí đầu tiên của mảng
            setA(setSortName); // set 1 mảng sort mới
            setSortNameId(1);
            setCheckColor("orange");
        } else if (checkColor === "orange") {
            setA([...data]); // set mảng về data ban đầu
            setCheckColor("white");
            setSortNameId(null);
        }
    };

    const moveUp = async (index, item, idUp) => {
        if (index === 0 || index === 1) return; // nếu phần tử đang ở vị trí đầu tiên và thứ 2 thì không thể di chuyển lên
        const newArray = [...a];
        const removedItem = newArray.splice(index, 1)[0];
        newArray.splice(index - 1, 0, removedItem);
        try {
            await weeklyScheduleApi.updateOrderListName({
                user_id: item.id,
                other_user_id: idUp,
                type: "up",
            });
        } catch (error) {}
        setA(newArray);
    };

    const moveDown = async (index, item, idDown) => {
        if (index === a.length - 1 || index === 0) return; // nếu phần tử đang ở vị trí cuối cùng thì không thể di chuyển xuống
        const newArray = [...a];
        const removedItem = newArray.splice(index, 1)[0];
        newArray.splice(index + 1, 0, removedItem);
        try {
            await weeklyScheduleApi.updateOrderListName({
                user_id: item.id,
                other_user_id: idDown,
                type: "down",
            });
        } catch (error) {}
        setA(newArray);
    };
    useEffect(() => {
        setA(data);
        setCheckDate(date);
    }, [data, date]);

    const { control, setValue, getValues } = form;
    const foundObject = data?.find((obj: any) => obj?.id === userInfo?.id);
    const handleGetUserProfile = () => {
        //clear form
        const valueForm = form.getValues();
        for (const key in valueForm) {
            setValue(key, "");
        }
        //set lại value
        foundObject?.schedule?.forEach((item) => {
            const dayIndex = getDayIndexMoment(item.date);
            setValue(`content_one${dayIndex}${moment(date).format("MM")}`, item.content_one);
            setValue(`content_two${dayIndex}${moment(date).format("MM")}`, item.content_two);
        });
    };
    useEffect(() => {
        handleGetUserProfile();
    }, [foundObject]);

    function handleCellClick(index) {
        setShowSubmitButton((prevState) => {
            return { ...prevState, [index]: true };
        });
    }

    const handleSubmit = async (event, i) => {
        event.preventDefault();
        setLoading(true);

        const getMonth = moment(date).format("MM");
        const getYear = moment(date).format("YYYY");

        const body = {
            data: [
                {
                    date: `${getYear}-${getMonth}-${i + 1}`,
                    content_one: getValues(`content_one${i + 1}${moment(date).format("MM")}`) || "",
                    content_two: getValues(`content_two${i + 1}${moment(date).format("MM")}`) || "",
                },
            ],
        };
        try {
            await weeklyScheduleApi.createNoteWeeklySchedule(body);
            setShowSubmitButton((prevState) => {
                return { ...prevState, [i]: false };
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    getDayIndexMoment(date);
    const dates = listHolidayDate?.map((date) => date.date);
    const days = dates?.map((date) => {
        return Number(date.split("-")[2]);
    });
    return (
        <StyledTableWrapper>
            <CircularLoader loading={loading} />
            <StyledTableViewWeeklySchedule>
                <table
                    className="tableNameList"
                    style={{ backgroundColor: "white" }}
                    ref={tableList}
                >
                    <tr
                        className="table__header"
                        onClick={sortName}
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <td
                            style={{
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                style={{
                                    marginTop: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <p>リスト名 </p>
                                <IoIosArrowDown
                                    style={{
                                        backgroundColor: checkColor,
                                        borderRadius: "50%",
                                        marginTop: "3px",
                                        marginLeft: "5px",
                                        fontSize: "20px",
                                    }}
                                />
                            </span>
                        </td>
                    </tr>
                    {!!a?.length &&
                        a?.map((item, index) => (
                            <tr
                                className="table__body tbListName"
                                key={item.id}
                                style={{ width: "50px !important" }}
                                ref={index === a?.length - 1 ? lastRowRef : null}
                            >
                                <td
                                    className="table__colName"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor:
                                            item.id === userInfo?.id ? "#f4eded" : " white",
                                    }}
                                >
                                    <p
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {item.name}
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            className="up-down-button"
                                            style={{
                                                marginRight: "5px",
                                                cursor:
                                                    index === 0 || index === 1
                                                        ? "default"
                                                        : "pointer",
                                                color:
                                                    index === 0 || index === 1
                                                        ? "#d3d3d3"
                                                        : "black",
                                            }}
                                            onClick={() => moveUp(index, item, a[index - 1]?.id)}
                                        >
                                            <ImArrowUp2 />
                                        </div>
                                        <div
                                            className="up-down-button"
                                            style={{
                                                cursor:
                                                    index === 0 || index === a.length - 1
                                                        ? "default"
                                                        : "pointer",
                                                color:
                                                    index === 0 || index === a.length - 1
                                                        ? "#d3d3d3"
                                                        : "black",
                                            }}
                                            onClick={() => moveDown(index, item, a[index + 1]?.id)}
                                        >
                                            <ImArrowDown2 />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </table>
            </StyledTableViewWeeklySchedule>
            <table className="table" ref={tableRef}>
                <tr className="table__header calendar">
                    {getDaysWeeksArrayMoment(date).map((day, index) => (
                        <>
                            <td
                                key={index}
                                className={clsx(
                                    day.dayInWeek === "sun" || day.dayInWeek === "sat"
                                        ? "dayOff"
                                        : ""
                                )}
                            >
                                {day.dayNumber}
                            </td>
                        </>
                    ))}
                </tr>
                {!!a?.length &&
                    a.map((event, indexEvent) => {
                        return (
                            <>
                                <tr className="table__body" key={indexEvent}>
                                    {(() => {
                                        const cols = [] as any;
                                        for (
                                            let i = 0;
                                            i < getDaysWeeksArrayMoment(date).length;
                                            i++
                                        ) {
                                            const index = i;
                                            const showSubmitBtn = showSubmitButton[index] || false;

                                            if (event.id === userInfo?.id) {
                                                cols.push(
                                                    <td
                                                        className="table__event"
                                                        colSpan={1}
                                                        onClick={() => handleCellClick(index)}
                                                        key={i}
                                                        style={{
                                                            backgroundColor: days.includes(i + 1)
                                                                ? "red"
                                                                : getDayIndexMoment(checkDate) ===
                                                                  i + 1
                                                                ? "#ebe6e6"
                                                                : "white",
                                                            // ? "red"
                                                            // : "#d3d3d3",
                                                        }}
                                                    >
                                                        <FormProvider {...form}>
                                                            <form
                                                                onSubmit={(e) =>
                                                                    handleSubmit(e, index)
                                                                }
                                                            >
                                                                <div
                                                                    className="event__name"
                                                                    style={{
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    <Controller
                                                                        name={`content_one${
                                                                            i + 1
                                                                        }${moment(date).format(
                                                                            "MM"
                                                                        )}`}
                                                                        control={control}
                                                                        render={({
                                                                            field: {
                                                                                value,
                                                                                onChange,
                                                                            },
                                                                            // fieldState: { error },
                                                                        }) => {
                                                                            return (
                                                                                <AppTextNote
                                                                                    handleChange={
                                                                                        onChange
                                                                                    }
                                                                                    value={value}
                                                                                    // handleBlur={handleBlur("contentOne")}
                                                                                />
                                                                                // <AppInput
                                                                                //     handleChange={
                                                                                //         onChange
                                                                                //     }
                                                                                //     value={value}
                                                                                // />
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="event__name">
                                                                    <Controller
                                                                        name={`content_two${
                                                                            i + 1
                                                                        }${moment(date).format(
                                                                            "MM"
                                                                        )}`}
                                                                        control={control}
                                                                        render={({
                                                                            field: {
                                                                                value,
                                                                                onChange,
                                                                            },
                                                                            // fieldState: { error },
                                                                        }) => (
                                                                            <AppTextNote
                                                                                handleChange={
                                                                                    onChange
                                                                                }
                                                                                value={value}
                                                                                // handleBlur={handleBlur("contentOne")}
                                                                            />
                                                                            // <AppInput
                                                                            //     handleChange={
                                                                            //         onChange
                                                                            //     }
                                                                            //     value={value}
                                                                            // />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <Button
                                                                    type="submit"
                                                                    style={{
                                                                        display: showSubmitBtn
                                                                            ? "block"
                                                                            : "none",
                                                                        width: "87px",
                                                                        height: "25px",
                                                                        backgroundColor: "black",
                                                                        color: "white",
                                                                        borderRadius: "10px",
                                                                        marginTop: "5px",
                                                                        fontSize: "13px",
                                                                    }}
                                                                >
                                                                    保存 +
                                                                </Button>
                                                            </form>
                                                        </FormProvider>
                                                    </td>
                                                );
                                            } else {
                                                for (let j = 0; j < data.length; j++) {
                                                    if (
                                                        getDayIndexMoment(
                                                            event.schedule[j]?.date
                                                        ) ===
                                                        getDaysWeeksArrayMoment(date)[i]?.dayNumber
                                                    ) {
                                                        cols.push(
                                                            <td
                                                                className="table__event"
                                                                colSpan={
                                                                    getDayIndexMoment(
                                                                        event.schedule[j]?.date
                                                                    ) -
                                                                    getDayIndexMoment(
                                                                        event.schedule[j]?.date
                                                                    ) +
                                                                    1
                                                                }
                                                                style={{
                                                                    backgroundColor: days.includes(
                                                                        i + 1
                                                                    )
                                                                        ? "red"
                                                                        : getDayIndexMoment(
                                                                              checkDate
                                                                          ) ===
                                                                          i + 1
                                                                        ? "#ebe6e6"
                                                                        : "white",
                                                                    // getDayIndexMoment(
                                                                    //     checkDate
                                                                    // ) ===
                                                                    // i + 1
                                                                    //     ? "#ebe6e6"
                                                                    //     : "white",
                                                                }}
                                                            >
                                                                <div
                                                                    className="event__name"
                                                                    style={{ margin: "5px" }}
                                                                >
                                                                    {event.schedule[j]?.content_one}
                                                                </div>
                                                                <Divider />
                                                                <div
                                                                    className="event__name"
                                                                    style={{ margin: "5px" }}
                                                                >
                                                                    {event.schedule[j]?.content_two}
                                                                </div>
                                                            </td>
                                                        );
                                                        i = getDayIndexMoment(
                                                            event.schedule[j]?.date
                                                        );
                                                    }
                                                }
                                                cols.push(
                                                    <td
                                                        onClick={() =>
                                                            handleOpenViewDetail &&
                                                            handleOpenViewDetail(event?.id)
                                                        }
                                                        style={{
                                                            backgroundColor: days.includes(i + 1)
                                                                ? "red"
                                                                : getDayIndexMoment(checkDate) ===
                                                                  i + 1
                                                                ? "#ebe6e6"
                                                                : "white",
                                                        }}
                                                    ></td>
                                                );
                                            }
                                        }
                                        return cols;
                                    })()}
                                </tr>
                            </>
                        );
                    })}
            </table>
        </StyledTableWrapper>
    );
});
