import { Grid } from "@mui/material";
import React from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import {
    StyledDocument,
    DocumentCategoryComponent,
    StyledIframe,
    DocumentListItemComponent,
} from ".";
import { AppTopBar, AppInput, TitleMenuComponent, AppModal, AppIconButton } from "..";
import { SearchIcon } from "@assets";
import { appRouteEnums, menuTitleEnums } from "@enums";
import {
    converNumberToArrayOfInterger,
    isLinkExel,
    linkDocument,
    useWindowDimensions,
} from "@utils";
import { StyledPDF } from ".";
import {
    API_CATEGORY_TYPE_DOCUMENT,
    DOCUMENT_EXCEL_FAILED_TO_LOAD,
    DOCUMENT_FAILED_TO_LOAD,
    MESSAGE_LOADING_PDF,
    PLACEHOLDER_SEARCH,
} from "@configs";
import { categoriesApi } from "@api";
import { useDispatch } from "react-redux";
import { updateSnackBar } from "@redux";
import { useHistory } from "react-router-dom";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const DocumentView = () => {
    // component state
    const [inputValue, setInputValue] = React.useState<string>("");
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [title, setTitle] = React.useState({
        category: "",
        subCategory: "",
    });
    const [categories, setCategories] = React.useState<Array<any>>();
    const [openPDF, setOpenPDF] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [listDocuments, setListDocuments] = React.useState<any>();
    const [subCategoryId, setSubCategoryId] = React.useState<any>("");
    const [linkPdf, setLinkPdf] = React.useState<any>("");
    // hook
    const messagesEndRef = React.useRef<any>(null);
    const width = useWindowDimensions().width;
    const dispatch = useDispatch();
    const history = useHistory();
    //What: scroll to bottom to view data results when device is mobile
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    //What: call api get categories
    React.useEffect(() => {
        const fetchCategoriesApi = async () => {
            try {
                const response = await categoriesApi.getCategories({
                    type: API_CATEGORY_TYPE_DOCUMENT,
                });
                setCategories(response.data.data);
            } catch (err: any) {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: err.response.data.message,
                    })
                );
            }
        };
        fetchCategoriesApi();
    }, []);
    //what: call api get list products
    const fetchListProducts = async (params: any) => {
        setLoading(true);
        try {
            const response = await categoriesApi.getListDocuments({
                category: params.category || "",
                name: params.name || "",
            });
            setLoading(false);
            setListDocuments(response.data.data);
        } catch (err: any) {
            setListDocuments(undefined);
            setLoading(false);
        }
    };
    //what: events
    const handleOnChange = (value: string) => {
        setInputValue(value);
    };
    const handleClosePDF = () => {
        setOpenPDF(false);
        setLinkPdf("");
    };
    const handleOpenPDF = (value: any) => {
        if (width <= 980) {
            window.open(linkDocument(value));
        }
        setLinkPdf(value);
        setOpenPDF(true);
    };
    //what: call api in search form
    const handleOnSearchSubmit = () => {
        fetchListProducts({
            name: inputValue,
        });
        setTitle({
            category: "",
            subCategory: "",
        });
        setSelectedIndex(-1);
        if (width < 768) {
            scrollToBottom();
        }
    };
    //what: call api after re-render
    //how: when sub category id is updated, this page is re-render
    React.useEffect(() => {
        fetchListProducts({
            category: subCategoryId,
        });
    }, [subCategoryId]);
    //what: select a subCategory ID
    const handleSelectSubCategory = (values: any) => {
        setTitle({
            category: values.category,
            subCategory: values.subCategory,
        });
        setSubCategoryId(values.subId);
        if (width < 768) {
            scrollToBottom();
        }
        setInputValue("");
    };
    //what: active select category
    const handleClick = (index: any) => {
        if (selectedIndex === index) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
        }
    };
    const handleDownloadPDF = () => {
        setOpenPDF(false);
    };
    const handleIframeLoad = () => {
        const wrapper = document.getElementById("pdfWrapper");
        (window as any).parentIFrame.size(wrapper?.offsetHeight, wrapper?.offsetWidth);
    };
    const [numPages, setNumPages] = React.useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <StyledDocument>
            <AppTopBar />
            {width >= 981 && (
                <AppModal open={openPDF} handleClose={handleClosePDF}>
                    <StyledPDF>
                        {linkPdf ? (
                            isLinkExel(linkPdf) ? (
                                <p className="failePDF">{DOCUMENT_EXCEL_FAILED_TO_LOAD}</p>
                            ) : width >= 981 ? (
                                <StyledIframe
                                    onLoad={handleIframeLoad}
                                    heightCalculationMethod="lowestElement"
                                    src={linkDocument(linkPdf)}
                                    log
                                    scrolling={true}
                                    autoResize={false}
                                />
                            ) : (
                                <Document
                                    file={linkDocument(linkPdf)}
                                    options={{ workerSrc: "/pdf.worker.js" }}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    error={DOCUMENT_FAILED_TO_LOAD}
                                    loading={MESSAGE_LOADING_PDF}
                                >
                                    {numPages &&
                                        converNumberToArrayOfInterger(numPages).map(
                                            (page, index) => <Page pageNumber={index} />
                                        )}
                                </Document>
                            )
                        ) : (
                            <p className="failePDF">{DOCUMENT_FAILED_TO_LOAD}</p>
                        )}
                    </StyledPDF>
                </AppModal>
            )}

            <div className="wrapperDocument">
                <div className="document">
                    <div className="document__top">
                        <p
                            className="newOT__top-icon"
                            onClick={() => history.push(appRouteEnums.PATH_HOME)}
                        >
                            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                        </p>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={8}>
                                <div className="document__top-title">
                                    <TitleMenuComponent text={menuTitleEnums.TITLE_DOCUMENT} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="document__top-searchForm">
                                    <AppInput
                                        value={inputValue}
                                        iconStart={<SearchIcon />}
                                        handleChange={handleOnChange}
                                        placeholder={PLACEHOLDER_SEARCH}
                                        disabled={false}
                                        onClick={handleOnSearchSubmit}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="document__content">
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <div className="category">
                                    {categories &&
                                        categories.map((item, index) => (
                                            <div key={item.id}>
                                                <DocumentCategoryComponent
                                                    category={item}
                                                    checked={index === selectedIndex ? true : false}
                                                    onClick={() => {
                                                        handleClick(index);
                                                    }}
                                                    handleSelectSubCategory={
                                                        handleSelectSubCategory
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <div className="listItem" ref={messagesEndRef}>
                                    <DocumentListItemComponent
                                        listDocument={listDocuments}
                                        title={title}
                                        handleOpenPDF={handleOpenPDF}
                                        loading={loading}
                                        handleDownloadPDF={handleDownloadPDF}
                                        handleClosePDF={handleClosePDF}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </StyledDocument>
    );
};
