import React from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

import { AppTopBar, TitleMenuComponent, AppInput, AppIconButton } from "..";
import { appRouteEnums, menuTitleEnums } from "@enums";
import { SearchIcon } from "@assets";
import { setInstrumentsCategory } from "@redux";
import {
    StyledInstrumentsView,
    InstrumentsListItemComponent,
    InstrumentCatetoryComponent,
} from ".";
import { categoriesApi, instrumentApi } from "@api";
import { updateSnackBar } from "@redux";
import { API_CATEGORY_TYPE_INSTRUMENT, PLACEHOLDER_SEARCH } from "@configs";
import { useWindowDimensions } from "@utils";
import { useHistory, useLocation } from "react-router-dom";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";

export const InstrumentsView = () => {
    // hooks
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");
    const subCategoryId = query.get("subCategoryId");
    const dispatch = useDispatch();
    const width = useWindowDimensions().width;
    const history = useHistory();
    //component state
    const [inputValue, setInputValue] = React.useState<string>("");
    const [categories, setCategories] = React.useState<Array<any>>();
    const [category, setCategory] = React.useState<string>("");
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const messagesEndRef = React.useRef<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [listProducts, setListProducts] = React.useState<any>();
    const [categoryViewMobile, setCategoryViewMobile] = React.useState<boolean>(true);
    // call api get categories
    React.useEffect(() => {
        const fetchCategoriesApi = async () => {
            try {
                const response = await categoriesApi.getCategories({
                    type: API_CATEGORY_TYPE_INSTRUMENT,
                });
                setCategories(response.data.data);
            } catch (err: any) {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: err.response?.data?.message,
                    })
                );
            }
        };
        fetchCategoriesApi();
    }, []);
    // events
    const handleOnChange = (value: string) => {
        setInputValue(value);
    };
    //What: scroll to bottom to view data results when device is mobile
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    //what: call api get list products
    const fetchListProducts = async (params: any) => {
        setLoading(true);
        try {
            const response = await instrumentApi.getViewListProducts({
                category: subCategoryId?.toString() || "",
                name: params.name || "",
            });
            setLoading(false);
            setListProducts(response.data.data);
        } catch (err: any) {
            setListProducts(undefined);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        const fetchCategoriesApi = async () => {
            try {
                const response = await categoriesApi.getCategories({
                    type: API_CATEGORY_TYPE_INSTRUMENT,
                });
                setCategories(response.data.data);
            } catch (err: any) {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: err.response.data.message,
                    })
                );
            }
        };
        fetchCategoriesApi();
    }, []);
    //what: call api in search form
    const handleOnSearchSubmit = () => {
        setCategoryViewMobile(false);
        fetchListProducts({
            name: inputValue,
            category: "",
        });
        setSelectedIndex(-1);
        if (width < 768) {
            scrollToBottom();
        }
        dispatch(
            setInstrumentsCategory({
                category: "",
                subCategory: "",
            })
        );
    };
    //what: select a subCategory ID
    const handleSelectCategory = (values: any, categoryId: number) => {
        dispatch(
            setInstrumentsCategory({
                category: values.title,
                subCategory: values.subTitle,
            })
        );
        setInputValue("");
        setCategoryViewMobile(false);
        setCategory(values.id);
        history.push(`/instruments?categoryId=${categoryId}&subCategoryId=${values.id}`);
    };
    //what: call api after re-render
    //how: when category id is updated, this page is re-render
    React.useEffect(() => {
        fetchListProducts({
            category: category,
        });
    }, [category]);
    //WHAT: active select category
    const handleClick = (index: any) => {
        setSelectedIndex(index);
    };
    return (
        <StyledInstrumentsView>
            <AppTopBar />
            <div className="instruments">
                <div className="instruments__top">
                    <p
                        className="newOT__top-icon"
                        onClick={() => history.push(appRouteEnums.PATH_HOME)}
                    >
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </p>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={8}>
                            <div className="instruments__top-title">
                                <TitleMenuComponent text={menuTitleEnums.TITLE_INSTRUMENTS} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="instruments__top-searchForm">
                                <AppInput
                                    value={inputValue}
                                    iconStart={<SearchIcon />}
                                    handleChange={handleOnChange}
                                    placeholder={PLACEHOLDER_SEARCH}
                                    disabled={false}
                                    name="searchValue"
                                    onClick={handleOnSearchSubmit}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="instruments__content visible-pc ">
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <div className="category">
                                {categories &&
                                    categories?.map((category, index) => (
                                        <div key={category.id}>
                                            <InstrumentCatetoryComponent
                                                category={category}
                                                checked={
                                                    String(categoryId) === String(category.id) ||
                                                    index === selectedIndex
                                                }
                                                onClick={() => {
                                                    handleClick(index);
                                                }}
                                                handleSelectSubCategory={handleSelectCategory}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <div className="listItem" ref={messagesEndRef}>
                                <InstrumentsListItemComponent
                                    loading={loading}
                                    products={listProducts}
                                    categoryId={category}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="instruments__content visible-mobile">
                    {categoryViewMobile ? (
                        <div className="category">
                            {categories &&
                                categories?.map((category, index) => (
                                    <div key={category.id}>
                                        <InstrumentCatetoryComponent
                                            category={category}
                                            checked={
                                                String(categoryId) === String(category.id) ||
                                                index === selectedIndex
                                            }
                                            onClick={() => {
                                                handleClick(index);
                                            }}
                                            handleSelectSubCategory={handleSelectCategory}
                                        />
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{ width: "100%", textAlign: "left", padding: "2rem 0" }}
                                onClick={() => setCategoryViewMobile(true)}
                            >
                                <img src={mobile_arrow_left} alt="icon" />
                            </div>
                            <InstrumentsListItemComponent
                                loading={loading}
                                products={listProducts}
                                categoryId={category}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledInstrumentsView>
    );
};
