import { Grid } from "@mui/material";
import React from "react";

import { StyledListItemDocument, DocumentItemComponent } from ".";
import { NotFoundItemView, CircularLoader } from "..";
interface IProps {
    listDocument?: any;
    loading?: boolean;
    title?: any;
    handleOpenPDF: (value: any) => void;
    handleDownloadPDF: (url: any) => void;
    handleClosePDF: () => void;
}
export const DocumentListItemComponent = (props: IProps) => {
    const {
        listDocument,
        loading,
        title,
        handleClosePDF,
        handleOpenPDF,
        handleDownloadPDF,
    } = props;
    return (
        <StyledListItemDocument>
            <div className="documentList__title">
                {title?.subCategory && (
                    <span>
                        {title?.category} ・ {title?.subCategory}
                    </span>
                )}
            </div>
            {loading ? (
                <div className="wrapperLoading">
                    <CircularLoader loading={loading} type="fullContent" />
                </div>
            ) : (
                <>
                    {listDocument?.length ? (
                        <div className="documentList__content">
                            <Grid container spacing={3}>
                                {listDocument?.map((item) => (
                                    <Grid key={item.id} item xs={12} sm={12} md={6}>
                                        <DocumentItemComponent
                                            handleOpenPDF={handleOpenPDF}
                                            item={item}
                                            handleDownloadPDF={handleDownloadPDF}
                                            handleClosePDF={handleClosePDF}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    ) : (
                        <div className="error">
                            <NotFoundItemView />
                        </div>
                    )}
                </>
            )}
        </StyledListItemDocument>
    );
};
