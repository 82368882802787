import styled from "styled-components";

import bg_profile from "@assets/Images/bg_profile.png";
import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledHomeContent = styled.div`
    .home {
        &__wrapper {
            background-image: url(${small_bg_dot});
            @media (min-width: 768px) {
                background-image: url(${bg_profile});
                background-color: ${(p) => p.theme.colors.white};
                height: 100%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                height: 100%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                height: 100%;
            }
        }
        &__topbar {
            background-color: ${(p) => p.theme.colors.white};
            border-bottom: 1px solid #f0f0f0;

            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding-top: 0;
            }
        }
        .wrapperContent {
            display: flex;
            justify-content: center;
        }
        &__content {
            padding: 1rem 1.5rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding: 2rem 8rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 2rem 11rem;
            }
            &-menu {
                display: block;
                background-color: ${(p) => p.theme.colors.white};
                border: 2px solid ${(p) => p.theme.colors.primary};
                border-radius: 2.4rem;
                padding: 2rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                    padding: 4rem 8rem;
                }
            }
            &-lists {
                padding-top: 3rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                    padding-right: 20rem;
                }
                .wrapperText {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 2rem;
                    padding-right: 1rem;
                }
                .text {
                    position: relative;
                    max-width: 3rem;
                    font-size: 3.4rem;
                    line-height: 4rem;
                    color: #000000;
                    z-index: 1;
                    font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 110%;
                        height: 80%;
                        background-color: ${(p) => p.theme.colors.primary};
                        z-index: -1;
                    }
                }
            }
        }

        &__btnModal {
            width: 40rem;
            max-width: 100%;
        }
    }
`;
export const StyledListItem = styled.div`
    padding: 2.6rem 3rem;
    border: 1px solid #d4d4d4;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        width: 9rem;
        background-color: ${(p) => p.theme.colors.secondary};
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 9rem;
        background-color: ${(p) => p.theme.colors.secondary};
    }
    .item {
        padding: 1.6rem 0;
        border-top: 1px solid ${(p) => p.theme.colors.black};
        height: 20.6rem;
        @media(min-width: 900px)  {
            /* min-height: 13.6rem; */
            height:auto;
        }
        &__top {
            display: flex;
            justify-content: space-between;
        }
        &__title {
            width: 105%;
            font-size: 1.4rem;
            cursor: pointer;
            word-break: break-all;
            padding-right: 1.5rem;
            color: #292d32;
            text-align: left;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        }
        &__amount {
            text-align: initial;
            color: #8e8e8e;
            font-size: 1.2rem;
        }
        &__code {
            font-size: 1.2rem;
            color: ${(p) => p.theme.colors.gray2};
            width: 13rem;
            text-align: right;
            cursor: pointer;
        }
        &__des {
            padding-top: 1.5rem;
            text-align: left;
            color: #656565;
            font-size: 1.2rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            word-break: break-all;
            cursor: pointer;
        }
    }
`;
