import React from "react";
import moment from "moment";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { StyleTimeSheet, TimeSheetCalendarComponent, TimeSheetToggleModal } from ".";
import {
    AppTopBar,
    CircularLoader,
    TitleMenuComponent,
    AppDatePicker,
    AppIconButton,
    AppCustomeSelect,
    AppExportCsv,
} from "..";
import tick_icon from "@assets/Icons/tick_icon.svg";
import { timesheetApi } from "@api";
import { updateSnackBar, selectAuth, useAppSelector } from "@redux";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { checkExistUserSelected, linkImage } from "@utils";
import styled from "styled-components";

const defaultGroup = {
    id: 0,
    name: "全て",
};

export const TimeSheetView = () => {
    const auth = useAppSelector(selectAuth);
    const id = auth?.userInfo?.id;

    // hooks
    const dispatch = useDispatch();
    const history = useHistory();
    // component state
    const [timeSheetDateData, setTimeSheetDateData] = React.useState<any>();
    const [timeSheetIdData, setTimeSheetIdData] = React.useState<any>();
    const [timesheetListData, setTimeSheeListData] = React.useState<any>([]);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [inputValue, setValueInput] = React.useState<any>();
    const [date, setDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState<boolean>();
    const [groups, setGroups] = React.useState<any>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
    const [users, setUsers] = React.useState<any>([]);
    React.useEffect(() => {
        fetchApiDetailTimeSheet({
            month: moment(date).format("YYYY-MM"),
            userId: inputValue?.value,
        });
    }, [date, selectedGroup, inputValue]);
    // fetch api timesheet
    const fetchApiDetailTimeSheet = async (params: any) => {
        setLoading(true);
        try {
            const responseGroups = await timesheetApi.listGroupsTimesheet();
            setGroups(responseGroups.data);
            const responseTimesheet = await timesheetApi.listTimesheetByGroup(
                params,
                selectedGroup
            );
            setTimeSheeListData(responseTimesheet.data);
            setSelectedGroup(selectedGroup ? selectedGroup : 0);
            const responseListUsers = await timesheetApi.listUsersInGroup(selectedGroup);
            setUsers(responseListUsers.data.data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
        }
    };
    // events
    const handleOpenModal = (date: any, id: number) => {
        setOpenModal(true);
        setTimeSheetDateData({
            date: date.startDate[0],
        });
        setTimeSheetIdData({
            id,
        });
    };
    const handleOpenModalId = (date: any, id: number) => {
        setOpenModal(true);
        setTimeSheetDateData({
            date: date.startDate,
        });
        setTimeSheetIdData({
            id,
        });
    };

    const handleCloseToggle = () => {
        setOpenModal(false);
        setTimeSheetDateData([]);
        setTimeSheetIdData("");
    };
    const handleChange = (date: any) => {
        setDate(date);
        setValueInput("");
    };

    const handleOnChange = (value) => {
        setValueInput(value);
    };
    const handleSelectGroup = (id) => {
        setValueInput("");
        if (id === 0) {
            setSelectedGroup([]);
            return;
        }
        setSelectedGroup(
            checkExistUserSelected(id, selectedGroup)
                ? selectedGroup.filter((e) => e !== id)
                : [...selectedGroup, id]
        );
    };
    return (
        <StyleTimeSheet>
            <AppTopBar />
            <TimeSheetToggleModal
                openToogle={openModal}
                anchor="right"
                handleCloseToggle={handleCloseToggle}
                timeSheetDateData={timeSheetDateData}
                id={timeSheetIdData}
                selectedGroup={selectedGroup}
            />
            <CircularLoader loading={loading} />
            <div className="timesheet">
                <p
                    className="newOT__top-icon"
                    onClick={() => history.push(appRouteEnums.PATH_HOME)}
                >
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
                <div className="timesheet__title to-link">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_TIMESHEET} />
                    <WrapLink onClick={() => history.push(appRouteEnums.PATH_WEEKLY_SCHEDULE)}>
                        週間予定
                    </WrapLink>
                </div>
                <div className="timesheet__datePicker">
                    <div className="label">作成日時</div>
                    <div className="wrapperDatePicker">
                        <AppDatePicker
                            date={date}
                            handleChange={handleChange}
                            format="yyyy/MM"
                            views={["year", "month"]}
                        />
                    </div>
                </div>
                <div className="timesheet__form">
                    <div className="wrapper__filter">
                        <div className="timesheet__filter">
                            <div
                                onClick={() => handleSelectGroup(defaultGroup.id)}
                                className={clsx(
                                    !selectedGroup.length ? "active" : "",
                                    "timesheet__filter-item"
                                )}
                            >
                                <span className="title">{defaultGroup.name}</span>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {!selectedGroup.length && <img src={tick_icon} alt="icon" />}
                                </Box>
                            </div>
                            {groups &&
                                groups.map((item) => (
                                    <div
                                        key={item.id}
                                        onClick={() => handleSelectGroup(item.id)}
                                        className={clsx(
                                            checkExistUserSelected(item.id, selectedGroup)
                                                ? "active"
                                                : "",
                                            "timesheet__filter-item"
                                        )}
                                    >
                                        <span className="title">{item.name}</span>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            {checkExistUserSelected(item.id, selectedGroup) && (
                                                <img src={tick_icon} alt="icon" />
                                            )}
                                        </Box>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="timesheet__search">
                        <div className="wrapperSelect">
                            <AppCustomeSelect
                                placeholder="人を選択する"
                                onChange={handleOnChange}
                                groupUserOptions={
                                    users?.length &&
                                    [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                        return {
                                            label: `${user.last_name}${user.first_name}`,
                                            value: user?.id?.toString(),
                                            avatar: linkImage(user?.avatar?.path),
                                        };
                                    })
                                }
                                options={
                                    users?.length &&
                                    [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                        return {
                                            label: `${user.last_name}${user.first_name}`,
                                            value: user?.id?.toString(),
                                            avatar: linkImage(user?.avatar?.path),
                                        };
                                    })
                                }
                                value={inputValue}
                                isShowAvatar
                                isClearable
                            />
                        </div>
                    </div>
                </div>
                <AppExportCsv type={"timesheet"} />
                <div className="timesheet__calendar">
                    <TimeSheetCalendarComponent
                        handleOpenModal={handleOpenModal}
                        handleOpenModalId={handleOpenModalId}
                        handleCloseToggle={handleCloseToggle}
                        timeSheetData={timesheetListData}
                        month={date}
                    />
                </div>
            </div>
        </StyleTimeSheet>
    );
};

const WrapLink = styled.div`
    font-weight: 700;
    margin-top: 27px;
    margin-left: 40px;
    border: 2px solid orange;
    color: orange;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 8px 10px;
    :hover {
        cursor: pointer;
        color: #d88d02;
        border: 2px solid #d88d02;
    }
`;
