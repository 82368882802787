import { StyledTimeSheetCalendar } from ".";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import React, { Children, cloneElement, useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import { StyledEventContent } from ".";
import { getMonthMoment, linkImage } from "@utils";
import clsx from "clsx";
import { selectAuth, useAppSelector } from "@redux";

const localizer = momentLocalizer(moment);

interface IProps {
    timeSheetData?: any;
    handleOpenModal: (date: any, id: any) => void;
    handleOpenModalId: (date: any, id: any) => void;
    handleCloseToggle: (date: any, id: any) => void;
    month?: any;
}
// const TouchCellWrapper = ({ children, value, onSelectSlot, onSelectEvent }) =>
//     cloneElement(Children.only(children), {
//         onClickCapture: (e) => {
//             e.stopPropagation();
//             e.preventDefault();
//             onSelectSlot({ action: "click", slots: [value] });
//             onSelectEvent({ action: "click", slots: [value] });
//         },
//         style: {
//             className: `${children}`,
//         },
//     });
const TouchCellWrapper = ({ children, value, onSelectSlot, onSelectEvent }) => {
    let startX = 0;
    let startY = 0;

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
        const deltaX = Math.abs(e.touches[0].clientX - startX);
        const deltaY = Math.abs(e.touches[0].clientY - startY);

        if (deltaY > deltaX) {
            e.stopPropagation();
        }
    };

    return cloneElement(Children.only(children), {
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onClick: () => {
            onSelectSlot({ action: "click", slots: [value] });
            onSelectEvent({ action: "click", slots: [value] });
        },
        style: {
            className: `${children}`,
        },
    });
};

export const TimeSheetCalendarComponent = React.memo((props: IProps) => {
    const [getId, setGetId] = useState<any>();
    const { timeSheetData, handleOpenModal, month, handleOpenModalId } = props;
    const [selectedEvent, setSelectedEvent] = useState<any>();

    useEffect(() => {
        if (selectedEvent) {
            handleOpenModalId(
                {
                    startDate: selectedEvent.startDate,
                },
                getId
            );
        }
    }, [selectedEvent]);

    const onSelectEvent = (event, id) => {
        if (getMonthMoment(event.startDate) === getMonthMoment(moment(month).format("YYYY-MM-DD"))) setSelectedEvent({ ...event, id });
    };
    const onSelectSlot = ({ action, slots }) => {
        if (action === "click") {
            if (getMonthMoment(moment(slots[0]).format("YYYY-MM-DD")) === getMonthMoment(moment(month).format("YYYY-MM-DD")))
                handleOpenModal(
                    {
                        startDate: slots,
                    },
                    getId
                );
            setGetId("");
        }
        return false;
    };

    const Event = (event: any) => {
        const ref = React.useRef(null);
        const auth = useAppSelector(selectAuth);
        const userInfo = auth.userInfo;

        const handleClick = (event) => {
            const userId = event.currentTarget.dataset.userId;

            setGetId(userId);
        };
        return (
            <Box sx={{ width: "100%", height: "100%" }} ref={ref}>
                <StyledEventContent>
                    <div className="groupUser">
                        <div className="user">
                            {event.event?.users &&
                                event.event?.users?.map((user) =>
                                    user.timesheets?.length ? (
                                        <div key={user.id} className={clsx(user.id === userInfo?.id ? "mine" : "", "wrapperAvatar")} onClick={handleClick} data-user-id={user.id}>
                                            <div className="content">
                                                <Avatar src={linkImage(user?.avatar?.path)} alt="avatar" />
                                                <span className="name">
                                                    {user?.last_name}
                                                    {user?.first_name}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )
                                )}
                        </div>
                    </div>
                </StyledEventContent>
            </Box>
        );
    };
    return (
        <StyledTimeSheetCalendar>
            <Calendar
                localizer={localizer}
                events={timeSheetData}
                startAccessor="startDate"
                endAccessor="endDate"
                defaultDate={month}
                date={month}
                components={{
                    event: Event,
                    dateCellWrapper: (props) => (
                        <TouchCellWrapper
                            // children={undefined}
                            children="selectable"
                            value={undefined}
                            {...props}
                            onSelectSlot={onSelectSlot}
                            onSelectEvent={(e) => onSelectEvent(e, getId)}
                        />
                    ),
                }}
                selectable={true}
                onSelectSlot={onSelectSlot}
                onSelectEvent={(e) => onSelectEvent(e, getId)}
                views={["month"]}
                longPressThreshold={250}
            />
        </StyledTimeSheetCalendar>
    );
});

// function Event(event: any) {
//     const ref = React.useRef(null);
//     const auth = useAppSelector(selectAuth);
//     const userInfo = auth.userInfo;
//     function handleClick(event) {
//         const userId = event.currentTarget.dataset.userId;
//         setGetId(userId);
//       }

//     return (
//         <Box sx={{ width: "100%", height: "100%" }} ref={ref}>
//             <StyledEventContent>
//                 <div className="groupUser">
//                     <div className="user">
//                         {event.event?.users &&
//                             getFirstOfListUsersTimeSheet(event.event?.users, 4)?.map((user) =>
//                                 user.timesheets?.length ? (
//                                     <div
//                                         key={user.id}
//                                         className={clsx(
//                                             user.id === userInfo?.id ? "mine" : "",
//                                             "wrapperAvatar"
//                                         )}
//                                         onClick={handleClick}
//                                         data-user-id={user.id}
//                                     >
//                                         <div className="content">
//                                             <Avatar
//                                                 src={linkImage(user?.avatar?.path)}
//                                                 alt="avatar"
//                                             />
//                                             <span className="name">
//                                                 {user?.last_name}
//                                                 {user?.first_name}
//                                             </span>
//                                         </div>
//                                     </div>
//                                 ) : (
//                                     ""
//                                 )
//                             )}
//                         {event.event?.users?.length > 4 && (
//                             <div className="more__dot">
//                                 <span className="mobile__dot">...</span>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </StyledEventContent>
//         </Box>
//     );
// };
