import React from "react";
import clsx from "clsx";
import moment from "moment";
// import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PlusIcon } from "@assets";
import { timesheetApi, workScheduleApi } from "@api";
import { PLACEHOLDER_CONSTRACTOR } from "@configs";
import { appRouteEnums, menuTitleEnums } from "@enums";
import { selectAuth, updateSnackBar, useAppSelector } from "@redux";
import { linkImage } from "@utils";
import { CreateTypeOfWorkForm, StyledWorkScheduleScreen, TableViewWorkSchedule, ViewGraphCalendar } from ".";
import { AppTopBar, TitleMenuComponent, WorkScheduleToggleModal, AppCustomeSelect, AppDatePicker, CircularLoader, AppModal, AppButton } from "..";
// import tick_icon from "@assets/Icons/tick_icon.svg";

// const defaultGroup = {
//     id: 0,
//     name: "全て",
// };

export const WorkScheduleDetailScreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;

    const { id } = useParams<{ id: any }>();

    // component states
    const [toggle, setToggle] = React.useState<number>(0);
    const [openDrawserToogle, setOpenDrawserToogle] = React.useState<boolean>(false);
    const [selectSubContractor, setSelectSubContractor] = React.useState<any>(null);
    const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
    const [date, setDate] = React.useState(new Date());
    const [users, setUsers] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(false);
    const [dataWorkSchedule, setDataWorkSchedule] = React.useState<any>([]);
    const [dataToggle, setDataToggle] = React.useState<any>();
    const [openNewForm, setOpenNewForm] = React.useState<boolean>(false);
    const [contructions, setContructions] = React.useState<any>();
    // const [groups, setGroups] = React.useState<any>([]);
    // const [employeeInput, setEmployeeInput] = React.useState<any>("");
    // const [filterData, setFilterData] = React.useState<any>([]);
    // const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
    // const [optionEmployee, setOptionEmployee] = React.useState<any[]>([]);
    const [reloadPage, setReloadPage] = React.useState<boolean>(false);

    const [itemDetail, setItemDetail] = React.useState<any>();
    React.useEffect(() => {
        fetchApiDetailWorkSchedule();
        fetchDetailWork();
    }, [selectedGroup, selectSubContractor, date, reloadPage]);

    React.useEffect(() => {
        fetchListConstructionSite();

        return () => {
            // clean up
        };
    }, []);

    const fetchDetailWork = async () => {
        try {
            const response = await workScheduleApi.getListWorksInConstruction({
                month: date && moment(date).format("YYYY-MM"),
                constructionId: id ? id : "",
                contractorId: selectSubContractor?.value ?? "",
            });
            if (response?.data?.success) {
                setItemDetail(response?.data?.data?.[0]);
            }
        } catch (err: any) {
            if (err.response?.status === 404) {
                setItemDetail(null);
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: err.response?.data?.message,
                    })
                );
            } else {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: err.response?.data?.message,
                    })
                );
            }
        }
    };
    const fetchApiDetailWorkSchedule = async () => {
        setLoading(true);
        try {
            // const responseGroups = await timesheetApi.listGroupsTimesheet();
            // setGroups(responseGroups.data);
            const responseListUsers = await timesheetApi.getListContractors();
            // setSelectedGroup(selectedGroup ? selectedGroup : 0);
            setUsers(responseListUsers?.data?.data?.contractors);
            const responseWorkSchedule = await workScheduleApi.getOverViewWorkSchedule(
                {
                    month: date && moment(date).format("YYYY-MM"),
                    userId: selectSubContractor?.value ? selectSubContractor.value : "",
                    constructionId: itemDetail?.id,
                },
                selectedGroup
            );
            // const responseGetListWorkSchedule = await workScheduleApi.getListWorksInConstruction({
            //     month: moment(date).format("YYYY-MM"),
            //     constructionId: id ? id : "",
            // });
            // setItemDetail(responseGetListWorkSchedule?.data?.data?.[0]);
            setDataWorkSchedule(responseWorkSchedule.data?.data);
            // setFilterData(responseWorkSchedule.data?.data);
            setLoading(false);
        } catch (err: any) {
            if (err.response?.status === 404) {
                setDataWorkSchedule([]);
            } else {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: err.response?.data?.message,
                    })
                );
            }
            setLoading(false);
        }
    };
    // const handleOnChange = (e) => {
    //     setEmployeeInput(e);
    //     setSelectFilterConstructions("");
    //     if (e !== null) {
    //         setDataWorkSchedule(filterData.filter((item) => item.employee?.id === parseInt(e?.value)));
    //     } else {
    //         setDataWorkSchedule(filterData);
    //     }
    // };
    // fetch api
    // const fetchListConstructions = async () => {
    //     try {
    //         const result = await timesheetApi.getListConstructions();
    //         setOptionSiteConstructions(result.data.data);
    //     } catch (err) {}
    // };
    const fetchCreateWorkScheduleApi = async (values: any, resetForm: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.storeWorkSchedule({
                ...values,
            });
            setLoading(false);
            // handleCloseNewForm(resetForm);
            setOpenNewForm(false);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.message,
                })
            );
            setReloadPage(!reloadPage);
            resetForm();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };
    // fetch api list timesheetApi
    const fetchListConstructionSite = async () => {
        try {
            const res = await workScheduleApi.getMyListContruction();
            setContructions(res.data.data);
            setReloadPage(!reloadPage);
        } catch (err) {}
    };
    // const fetchListEmployee = async () => {
    //     try {
    //         const res = await timesheetApi.listEmployees();
    //         setOptionEmployee(res?.data?.data);
    //     } catch (err) {}
    // };
    // events
    const handleCloseDrawserToggle = () => {
        setOpenDrawserToogle(false);
        setDataToggle("");
    };
    const handleOpenDrawserToggle = (id) => {
        const indexData = dataWorkSchedule.findIndex((e) => e.id === id);
        setDataToggle(dataWorkSchedule[indexData]);
        setOpenDrawserToogle(true);
    };
    const handleOnChangeSelect = (id) => {
        setSelectSubContractor(id);
    };
    // const handleSelectGroup = (id) => {
    //     setSelectSubContractor("");
    //     if (id === 0) {
    //         setSelectedGroup([]);
    //         return;
    //     }
    //     setSelectedGroup(checkExistUserSelected(id, selectedGroup) ? selectedGroup.filter((e) => e !== id) : [...selectedGroup, id]);
    // };
    const handleChangeMonth = (date: any) => {
        setDate(date);
        setSelectedGroup([]);
    };

    const handleOpenViewDetail = (id) => {
        history.push(`${appRouteEnums.PATH_WORK_SCHEDULE}/${id}`);
    };
    const handleOpenNew = () => {
        setOpenNewForm(true);
    };
    const handleCloseNewForm = (resetForm: any) => {
        resetForm();
        setOpenNewForm(false);
    };
    const handleOnCreateSchedule = (values: any, resetForm: any) => {
        fetchCreateWorkScheduleApi(values, resetForm);
    };

    return (
        // <StyledWorkScheduleScreen isCollaspe={isCollapse}>
        <StyledWorkScheduleScreen>
            <AppTopBar />

            {openNewForm && (
                <AppModal open={openNewForm} handleClose={() => setOpenNewForm(false)}>
                    <CreateTypeOfWorkForm data={itemDetail} handleCloseNew={handleCloseNewForm} handleOnCreateSchedule={handleOnCreateSchedule} listContructions={contructions} />
                </AppModal>
            )}

            <WorkScheduleToggleModal anchor="right" openToogle={openDrawserToogle} handleCloseToggle={handleCloseDrawserToggle} data={dataToggle} />
            <CircularLoader loading={loading} />

            <div className="workSchedule">
                <div>
                    <div className="workSchedule__title">
                        <TitleMenuComponent text={menuTitleEnums.TITLE_WORK_SCHEDULE} />
                    </div>
                    <div className="workSchedule__toggle">
                        <span className={clsx(toggle === 0 ? "activeToggle" : "", "left")} onClick={() => setToggle(0)}>
                            テーブルビュー
                        </span>
                        <span className={clsx(toggle === 1 ? "activeToggle" : "", "right")} onClick={() => setToggle(1)}>
                            グラフビュー
                        </span>
                    </div>

                    <div className="workSchedule__form">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <div className="workSchedule__select">
                                    <div className="wrapperSelect">
                                        {!!users?.length && (
                                            <AppCustomeSelect
                                                placeholder={PLACEHOLDER_CONSTRACTOR}
                                                onChange={handleOnChangeSelect}
                                                groupUserOptions={
                                                    users?.length &&
                                                    users.map((user) => {
                                                        return {
                                                            label: `${user.name}`,
                                                            value: user?.id?.toString(),
                                                            avatar: linkImage(user?.avatar?.path),
                                                        };
                                                    })
                                                }
                                                options={
                                                    users?.length &&
                                                    users.map((user) => {
                                                        return {
                                                            label: `${user.name}`,
                                                            value: user?.id?.toString(),
                                                            avatar: linkImage(user?.avatar?.path),
                                                        };
                                                    })
                                                }
                                                isClearable
                                                value={selectSubContractor}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            {/* <Grid item xs={12} md={8}>
                                <div className="workSchedule__filter">
                                    <div onClick={() => handleSelectGroup(defaultGroup.id)} className={clsx(!selectedGroup.length ? "active" : "", "workSchedule__filter-item")}>
                                        <span className="title">{defaultGroup.name}</span>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>{!selectedGroup.length && <img src={tick_icon} alt="icon" />}</Box>
                                    </div>
                                    {groups &&
                                        groups.map((item) => (
                                            <div key={item.id} onClick={() => handleSelectGroup(item.id)} className={clsx(checkExistUserSelected(item.id, selectedGroup) ? "active" : "", "workSchedule__filter-item")}>
                                                <span className="title">{item.name}</span>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {checkExistUserSelected(item.id, selectedGroup) && <img src={tick_icon} alt="icon" />}
                                                </Box>
                                            </div>
                                        ))}
                                </div>
                            </Grid> */}
                            {/* <Grid item xs={12} md={4}>
                                <Grid item xs={7} md={8}>
                                    <label className="label">従業員</label>
                                    <div className="input select">
                                        {!!optionSiteConstructions && (
                                            <AppCustomeSelect
                                                placeholder="人を選択する"
                                                onChange={handleOnChange}
                                                groupUserOptions={
                                                    optionEmployee?.length &&
                                                    optionEmployee.map((user) => {
                                                        return {
                                                            label: `${user.last_name}${user.first_name}`,
                                                            value: user?.id?.toString(),
                                                            avatar: linkImage(user?.avatar?.path),
                                                        };
                                                    })
                                                }
                                                options={
                                                    optionEmployee?.length &&
                                                    optionEmployee.map((user) => {
                                                        return {
                                                            label: `${user.last_name}${user.first_name}`,
                                                            value: user?.id?.toString(),
                                                            avatar: linkImage(user?.avatar?.path),
                                                        };
                                                    })
                                                }
                                                isClearable
                                                value={employeeInput}
                                                isShowAvatar
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid> */}

                            <Grid item xs={12} md={4}>
                                <div className="table_component">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>担当</th>
                                                <th>工事名（略名）</th>
                                                <th>工期</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="truncate ">
                                                        {" "}
                                                        {itemDetail?.employee?.last_name} {itemDetail?.employee?.first_name}
                                                    </div>{" "}
                                                </td>
                                                <td>
                                                    <div className="truncate "> {itemDetail?.name}</div>{" "}
                                                </td>
                                                <td>
                                                    {itemDetail?.start_date}
                                                    <br /> {itemDetail?.end_date}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className="wrapperInput">
                                    <div className="workSchedule__datePicker">
                                        <div className="label">日付</div>
                                        <div className="wrapperDatePicker">
                                            <AppDatePicker date={date} handleChange={handleChangeMonth} format="yyyy/MM" views={["year", "month"]} />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="workSchedule__content">
                        <div className="visible__mobile">
                            {toggle === 0 && <TableViewWorkSchedule handleCloseDrawserToggle={handleCloseDrawserToggle} handleOpenDrawserToggle={handleOpenDrawserToggle} data={itemDetail} setReloadPage={setReloadPage} reloadPage={reloadPage} />}
                            {toggle === 1 && <ViewGraphCalendar data={itemDetail} handleOpenViewDetail={handleOpenViewDetail} date={date} fetchDetailApi={fetchDetailWork} selectSubContractor={selectSubContractor} />}
                        </div>
                        <div className="visible__desktop" style={{ marginTop: "40px" }}>
                            {/* <span className="btnCollaspe">
                                <AppIconButton icon={<ArrowLeftIcon />} onClick={() => setCollapse(!isCollapse)} />
                            </span> */}
                            <div className="wrapperDesktopContent">
                                <TableViewWorkSchedule hasCollapse={true} handleCloseDrawserToggle={handleCloseDrawserToggle} handleOpenDrawserToggle={handleOpenDrawserToggle} data={itemDetail} setReloadPage={setReloadPage} reloadPage={reloadPage} />
                                <div className="wrapperDesktopContent__graphView">
                                    <ViewGraphCalendar data={itemDetail} handleOpenViewDetail={handleOpenViewDetail} date={date} fetchDetailApi={fetchDetailWork} selectSubContractor={selectSubContractor} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {contructions?.length && itemDetail?.employee?.id === userInfo?.id && (
                        <div className="btn__add">
                            <div className="btn__wrapper">
                                <AppButton onClick={handleOpenNew} text="新しい工種を追加する" startIcon={<PlusIcon />} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </StyledWorkScheduleScreen>
    );
};
