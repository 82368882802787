import styled from "styled-components";
import inventory_bg from "@assets/Background/inventory_bg.png";

export const StyledListDocumentComponent = styled.div`
    padding-bottom: 4rem;
    background-repeat: no-repeat;
    min-height: 100vh;
    border-top: 1px solid #f0f0f0;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        background-image: url(${inventory_bg});
    }
    .top-icon{
        text-align: left;
    }
    .wrapperForm {
        width: 100%;
        padding: 0rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 0rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 0rem 11rem;
        }
        &-top{
            padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 1rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem 0rem 11rem;
        }
        }
    }
    .MuiDivider-root {
        display: none;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display: block;
        }
    }

    .form {
        /* display: flex;
        flex-direction: column; */
        padding: 1.6rem 1rem;
        padding-bottom: 0;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            flex-direction: row;
            justify-content: center;
            min-width: 80rem;
            padding-left: 8rem;
            padding-right: 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding-left: 0;
            padding-right: 0;
        }
        &__search {
            padding: 1.6rem 0;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
        }
        &__checkbox {
            padding: 1.6rem 0;
            width: 100%;
            padding-left: 0.5rem;
            border-top: 1px solid #f0f0f0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                /* display: flex;
                justify-content: flex-end; */
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            width: 50%;
            display: flex;
            justify-content: flex-end;
        }
        .MuiFormGroup-root {
            flex-direction: row;
        }
        .MuiTypography-root {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            font-size: 1.2rem;
            color: #d5d5d5;
        }
        .MuiSvgIcon-root {
            width: 1.5em;
            height: 1.5em;
        }
        .active {
            .MuiTypography-root {
                color: ${(p) => p.theme.colors.darkBlack} !important;
            }
        }
    }

    .noti {
        display: flex;
        justify-content: center;
        padidng-top: 4rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding-left: 8rem;
            padding-right: 8rem;
            padding-top: 4rem;
        }
        &__wrapper {
            width: 90%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 100%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 80rem;
            }
        }
        &__content {
            max-width: 80rem;
        }
    }
    .wrapperLoading {
        display: flex;
        justify-content: center;
        padding-top: 2rem;
    }
    .viewMore {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 4rem;
        cursor: pointer;
        &__text {
            font-size: 1.6rem;
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            color: ${(p) => p.theme.colors.secondary};
            text-decoration: underline;
        }
        &__icon {
            margin-left: 0.5rem;
            padding-top: 0.5rem;
        }
    }
`;
export const StyledListDocumentItem = styled.div`
    .noti__item {
        display: flex;
        text-align: left;
        padding: 1.6rem 0.5rem;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            justify-content: space-between;
        }
        &-deleteIcon{
            width: 3rem;
            height: 3rem;
            &hover{
                cursor: pointer;
            } 
        }
        &-icon {
            display: flex;
            justify-content: center;
        }
        &-content {
            display: flex;
            width: 75%;
            /* flex-direction: column; */
            /* justify-content: space-around; */
            /* align-items: stretch; */
            padding-left: 1rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 90%;
            }
        }
        &-title {
            margin-left: 3rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            display: flex !important;
            align-items: center;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-description {
            font-size: 1.2rem;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.2;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-status {
            display: flex;
            align-items: center;
            padding-left: 1.5rem;
        }
    }
    .icon {
        &__vehicle {
            background-color: #ffeedf !important;
        }
        &__instrument {
            background-color: #ffdff8 !important;
        }
        &__overtime {
            background-color: #d1e9ff !important;
        }
    }
`;
